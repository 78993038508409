import { FC } from "react";
import "./style.scss";
interface ButtonI {
  text: string;
  clickHandler: () => void;
}
const Button: FC<ButtonI> = ({ text, clickHandler }) => {
  return (
    <div className={`button-wrapper`} onClick={clickHandler}>
      <div className={`button-wrapper__text`}>{text}</div>
    </div>
  );
};

export default Button;

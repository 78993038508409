import React, { FC } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

interface browserTitleI {
  title: string;
}

const BrowserTitle: FC<browserTitleI> = ({ title }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>MANAAKI | {title}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default BrowserTitle;

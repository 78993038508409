import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import clsx from 'clsx';

interface circleIconButtonI {
  icon: any;
  size?: 'sm' | 'md';
  theme?:
    | 'grey-red'
    | 'dark-grey-white'
    | 'dark-grey-red'
    | 'red-white'
    | 'blue-white';
  badge?: string | number;
  onClick?: Function;
}

const CircleIconButton: FC<circleIconButtonI> = ({
  icon,
  theme = 'grey-red',
  size = 'md',
  badge,
  onClick,
}) => {
  const buttonClicked = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) onClick(event);
  };

  return (
    <button
      type="button"
      onClick={buttonClicked}
      className={clsx(
        'circle-icon-button',
        'circle-icon-button--' + size,
        'circle-icon-button--' + theme
      )}
    >
      <FontAwesomeIcon icon={icon} className="circle-icon-button__icon" />
      {badge && <div className="circle-icon-button__badge">{badge}</div>}
    </button>
  );
};

export default CircleIconButton;

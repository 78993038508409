export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      profileID
      name
      introduction
      private
      mainImage
      status
    }
  }
`;

export const listPosts = /* GraphQL */ `
  query ListPosts {
    listPosts {
      nextToken
      items {
        content
        parentPostID
        profileID
        groupID
        createdAt
        status
        type
        title
        id
      }
    }
  }
`;

export const listAdvisors = /* GraphQL */ `
  query ListAdvisors(
    $businessIndustry: String = ""
    $role: Int = 4
    $ne: Int = 0
  ) {
    listProfiles(
      filter: {
        role: { eq: $role }
        status: { ne: $ne }
        businessIndustry: { eq: $businessIndustry }
      }
    ) {
      items {
        avatarImage
        firstName
        lastName
        role
        rating
        id
      }
    }
  }
`;

export const listProfilesWithDetails = /* GraphQL */ `
  query ListProfilesWithDetails($cognitoId: String) {
    listProfiles(filter: { cognitoId: { eq: $cognitoId } }) {
      items {
        avatarImage
        cognitoId
        email
        firstName
        id
        lastName
        type
      }
    }
  }
`;

import { FC } from 'react';
import './style.scss';

const InputWithTextArea: FC<any> = ({
  placeholder,
  name,
  register,
  required,
  minLength,
  className,
  label,
  rows = 5,
}) => {
  return (
    <div className={`textarea-holder ${className}`}>
      {label && <label className="label">{label}</label>}
      <textarea
        name={name}
        rows={rows}
        placeholder={placeholder}
        ref={register({ required, minLength: minLength })}
      ></textarea>
    </div>
  );
};

export default InputWithTextArea;

import { FC, useEffect, useState } from "react";
import "./styles.scss";

const SideBar: FC<any> = ({
  showSideNav,
  children,
  direction = "left",
  className,
}) => {
  const [showWithAnimation, toggleShowWithAnimation] = useState(false);
  useEffect(() => {
    //TODO: To handle the animation effect of slider open close here.
    setTimeout(() => {
      toggleShowWithAnimation(true);
    }, 500);
  }, []);
  return (
    <div
      className={`sidebar-container ${className} ${
        showSideNav && showWithAnimation ? `show-slider-${direction}` : ""
      } menu ${direction}`}
    >
      <div className={""}>{children}</div>
    </div>
  );
};

export default SideBar;

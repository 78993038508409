import { FC, useState, useEffect } from 'react';
import Title from '../../../../components/title';
import { useForm } from 'react-hook-form';
import Input from '../../../../components/form/input';
import InputWithTextArea from '../../../../components/form/inputWithTextArea';
import AdvisorsList from '../../../../components/AdvisorsList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import './style.scss';

const EditProfile: FC<any> = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { register, handleSubmit, setValue } = useForm<any>();
  const [advisorsList, setAdvisorsList] = useState<any[]>([
    {
      id: 1,
      firstName: 'Cameron',
      lastName: 'Smitthers',
      rating: 5,
    },
    {
      id: 2,
      firstName: 'Anne',
      lastName: 'Williams',
      rating: 5,
    },
    {
      id: 3,
      firstName: 'Mandeep',
      lastName: 'Ahuja',
      rating: 5,
    },
  ]);

  useEffect(() => {
    console.log(user);
    populateEditProfile();
  }, []);

  const populateEditProfile = () => {
    const { firstName, lastName, email, location } = user;
    setValue('name', firstName + ' ' + lastName);
    setValue('email', email);
    setValue('location', location);
  };

  const onSubmit = (editProfileObj: any, e: any) => {
    console.log(editProfileObj);
  };

  const onAdvisorSelect = (clickedAdvisor: any) => {
    const newAdvisorList = [...advisorsList];
    newAdvisorList.map((advisor: any) => {
      if (advisor.id === clickedAdvisor.id && advisor.selected) {
        advisor.selected = false;
      } else if (advisor.id === clickedAdvisor.id && !advisor.selected) {
        advisor.selected = true;
      }
      return advisor;
    });
    setAdvisorsList(newAdvisorList);
  };

  return (
    <div className="edit-profile">
      <div className="right-section__top">
        <Title title="Edit Profile" />
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="edit-profile__form">
        <div className="col-2">
          <Input
            id={0}
            placeholder={'Name'}
            name={'name'}
            register={register}
            type={'text'}
            label={'Name'}
            className={'edit-profile name'}
          />
          <Input
            id={1}
            placeholder={'Email'}
            name={'email'}
            register={register}
            type={'email'}
            label={'Email'}
            className={'edit-profile email'}
          />
        </div>
        <Input
          id={2}
          placeholder={'Location'}
          name={'location'}
          register={register}
          type={'text'}
          label={'Location'}
          className={'edit-profile location'}
        />
        <div className="col-2">
          <Input
            id={3}
            placeholder={'Label'}
            name={'addlinks'}
            register={register}
            type={'text'}
            label={'Add Links'}
            className={'edit-profile add-links'}
          />
          <Input
            id={4}
            placeholder={'Link'}
            name={'link'}
            register={register}
            type={'text'}
            label={'Link'}
            className={'edit-profile link'}
          />
        </div>
        <InputWithTextArea
          id={5}
          name={'bio'}
          register={register}
          label={'Bio'}
          className={'edit-profile bio'}
        />
        <div className="col-2">
          <Input
            id={6}
            name={'banner'}
            register={register}
            type={'text'}
            label={'Add Banner'}
            className={'edit-profile banner'}
          />
          <Input
            id={7}
            name={'profilePic'}
            register={register}
            type={'text'}
            label={'Add Profile Picture'}
            className={'edit-profile profile-pic'}
          />
        </div>

        <div className="manage-connections">
          <div className="right-section__top">
            <Title title="Manage Connections" />
          </div>
          <Input
            id={8}
            name={'username'}
            register={register}
            type={'text'}
            label={'Enter Username'}
            className={'edit-profile username'}
          />
          <div className="advisors-list-wrapper">
            <AdvisorsList
              advisorsList={advisorsList}
              onAdvisorSelect={onAdvisorSelect}
            />
          </div>
        </div>

        <div className="save-btn-wrapper">
          <button type="submit">SAVE</button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;

import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

export const AuthSignIn = async (username: string, password: string) => {
  try {
    if (username && password) {
      let user = await Auth.signIn(username, password);
      console.log('logged in', user.userDataKey);

      try {
        const myProfiles: any = await API.graphql({
          query: queries.searchProfiles,
          variables: {
            filter: {
              cognitoId: { eq: user.userDataKey },
            },
          },
        });
        console.log('myProfiles', myProfiles);
        if (myProfiles.data.searchProfiles.items.length == 0) {
          console.log('adding profile');
          const newProfile: any = await API.graphql({
            query: mutations.createProfile,
            variables: {
              input: {
                cognitoId: user.userDataKey,
                firstName: username,
                lastName: '',
                role: 4,
                location: '',
                onboarded: 0,
              },
            },
          });
          let obj = newProfile.data.createProfile;
          obj.unreadMessageCount = getUnreadMessagesCount(
            newProfile.data.createProfile.id
          );
          console.log('userobj', obj);
          return obj;
        } else {
          let obj = myProfiles.data.searchProfiles.items[0];
          obj.unreadMessageCount = getUnreadMessagesCount(
            myProfiles.data.searchProfiles.items[0].id
          );
          console.log('userData dispatched (MY_PROFILE) for user ' + obj.id);
          console.log('userobj', obj);
          return obj;
        }
      } catch (err) {
        console.log(err);
      }
    }
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const setAuthListener = async () => {
  try {
    let user = await Auth.currentAuthenticatedUser();
    var userData = await getProfileByCognitoId(user.userDataKey);
    if (userData.hasOwnProperty('id')) {
      return userData;
      // dispatch(getProfileByCognitoId_dispatch(userData))
      // var menuObj = await getMainMenu(userData.id)
      // dispatch(getMainMenu_dispatch(menuObj))
      // navigation.navigate('HomeScreen')
    } else {
      console.log('user data not found');
      return {};
    }
  } catch (err) {
    console.log(err);
    return {};
  }
};

export async function getProfileByCognitoId(cognitoId: any) {
  console.log('action - getProfileByCognitoId');
  try {
    //setIsloading(true)
    const myProfile: any = await API.graphql({
      query: queries.searchProfiles,
      variables: {
        filter: {
          cognitoId: { eq: cognitoId },
        },
      },
    });

    let obj = myProfile.data.searchProfiles.items[0];
    //obj.unreadMessageCount = getUnreadMessagesCount(myProfile.data.listProfiles.items[0].id)
    //clean up user friends for dynamic stateful response
    let friends_arr = [obj.id];
    //console.log('obj.friends',obj.friends)
    for (var i = 0; i < obj.friends?.items?.length; i++) {
      friends_arr.push(obj.friends.items[i].FriendProfileID);
    }
    obj.friends_arr = friends_arr;
    console.log('userObj', obj);
    return obj;

    // return apiReq || []
  } catch (error) {
    console.log('action - getProfileByCognitoId - error', error);
    return {};
  }
}

async function getUnreadMessagesCount(profileID: any) {
  const myThreads: any = await API.graphql({
    query: queries.listMessageThreads,
    variables: {
      filter: {
        profileID: { eq: profileID },
        read: { eq: '0' },
      },
    },
  });

  return myThreads.data.listMessageThreads.items.length;
}

import { FC } from 'react';
import Title from '../../../../components/title';
import { Questions } from '../../../../components/QuestionsList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import './style.scss';
const Discussions: FC<any> = () => {
  const { isMobile } = useSelector((state: RootState) => state.windowR);
  return (
    <div className="discussions">
      <div className={`right-section__top`}>
        <Title title="Your Questions" />
      </div>
      <Questions isMobile={isMobile} />

      <div className={`right-section__top`}>
        <Title title="Archived" />
      </div>
      <Questions isMobile={isMobile} />
    </div>
  );
};

export default Discussions;

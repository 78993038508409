import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { industryI } from "../../types";
import "./style.scss";

interface filterActivitiesI {
  onFilter: Function;
  filterOptions: industryI[];
  className?: string;
}

const FilterActivities: FC<filterActivitiesI> = ({
  onFilter,
  filterOptions,
  className,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    setOpen((o) => !o);
  };

  return (
    <div className={`filter-activities ${className}`}>
      <button onClick={toggleOpen} className="filter-activities__button">
        <div className="filter-activities__text">Filter by</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className="filter-activities__icon"
        />
      </button>
      {open && (
        <div className="filter-activities__options">
          {filterOptions.map((option) => {
            return (
              <div key={option.id} className="title">
                {option.title}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterActivities;

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
export const Questions: FC<any> = ({ isMobile }) => {
  const questionsData = [
    {
      id: 1,
      question: 'How do I convience customers to...',
      date: '31/10/20',
      answered: 'Yes',
    },
    {
      id: 2,
      question: 'How do I convience customers to...',
      date: '31/10/20',
      answered: 'Yes',
    },
    {
      id: 3,
      question: 'How do I convience customers to...',
      date: '31/10/20',
      answered: 'Yes',
    },
  ];
  return (
    <div className="questions-section-wrapper">
      <div className={`header`}>
        <div className="question-title">Question:</div>
        {!isMobile && <div className="date-title">Date:</div>}
        {!isMobile && <div className="answer-title">Answered:</div>}
      </div>
      <div className={`questions-list`}>
        {questionsData.map((item) => {
          return (
            <div className={'questions-list__item'} key={item.id}>
              <div className="search">
                <FontAwesomeIcon icon={faSearch} className="search__icon" />
              </div>
              <div className="question">{item.question}</div>
              {!isMobile && <div className="date">{item.date}</div>}
              {!isMobile && <div className="answered">{item.answered}</div>}
              <div className="view-btn-wrapper">
                <div>View</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const FileUpload: FC<any> = ({ className, label, onChange, name }) => {
  return (
    <div className={`file-upload ${className}`}>
      {label && <label className="label">{label}</label>}
      <div className="input-holder">
        <input type="file" onChange={onChange} name={name} />
        <FontAwesomeIcon icon={faArrowUp} className="icon-arrow-up" />
      </div>
    </div>
  );
};

export default FileUpload;

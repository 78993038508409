import { createSlice } from "@reduxjs/toolkit";
import { userI } from "./../../types";

interface suggestedConnectionsInitialStateI {
  users: userI[];
  loading: boolean;
}

const initialState: suggestedConnectionsInitialStateI = {
  users: [
    {
      id: "1",
      firstName: "Jackson",
      lastName: "Curtis",
      occupation: "Small Business Owner",
      avatarImage: "https://picsum.photos/id/1010/40/40",
      certified: true,
      type: "contractor",
    },
    {
      id: "2",
      firstName: "Jackson",
      lastName: "Curtis",
      occupation: "Small Business Owner",
      avatarImage: "https://picsum.photos/id/1011/40/40",
      certified: true,
      type: "contractor",
    },
    {
      id: "3",
      firstName: "Jackson",
      lastName: "Curtis",
      occupation: "Small Business Owner",
      avatarImage: "https://picsum.photos/id/1012/40/40",
      certified: true,
      type: "contractor",
    },
  ],
  loading: false,
};

const suggestedConnectionsSlice = createSlice({
  name: "suggestedConnections",
  initialState,
  reducers: {},
});

const { reducer } = suggestedConnectionsSlice;
export default reducer;

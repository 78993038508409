/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      profileID
      name
      introduction
      content
      private
      mainImage
      status
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      profileID
      name
      introduction
      private
      mainImage
      primaryIndustry
      category
      location
      experience
      skills
      rateFrom
      rateTo
      hoursNeeded
      content
      shareCount
      status
      featured
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      groupID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupMembers = /* GraphQL */ `
  query ListGroupMembers(
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        profileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectMember = /* GraphQL */ `
  query GetProjectMember($id: ID!) {
    getProjectMember(id: $id) {
      id
      projectID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjectMembers = /* GraphQL */ `
  query ListProjectMembers(
    $filter: ModelProjectMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        profileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuote = /* GraphQL */ `
  query GetQuote($id: ID!) {
    getQuote(id: $id) {
      id
      profileID
      projectID
      content
      timeFrame
      rateFrom
      rateTo
      links
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listQuotes = /* GraphQL */ `
  query ListQuotes(
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        projectID
        content
        timeFrame
        rateFrom
        rateTo
        links
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      profileID
      title
      groupID
      projectID
      content
      type
      mainImage
      status
      parentPostID
      shareCount
      private
      introduction
      tags
      links
      createdAt
      searchString
      importID
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      replies {
        nextToken
      }
      comments {
        nextToken
      }
      flames {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      industries {
        nextToken
      }
      interests {
        nextToken
      }
      ratings {
        nextToken
      }
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      profileID
      postID
      sharedID
      content
      groupID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      flames {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        postID
        sharedID
        content
        groupID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      profileID
      postID
      recipientProfileID
      content
      stars
      createdAt
      updatedAt
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        postID
        recipientProfileID
        content
        stars
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      cognitoId
      firstName
      lastName
      role
      onboarded
      location
      type
      rateFrom
      rateTo
      rateCurrency
      behanceLink
      instagramLink
      facebookLink
      linkedinLink
      twitterLink
      introduction
      experience
      mainImage
      avatarImage
      rating
      manaakiCertified
      occupation
      businessName
      businessIndustry
      skills
      featured
      status
      searchString
      importID
      importEmail
      email
      posts {
        nextToken
      }
      groups {
        nextToken
      }
      projects {
        nextToken
      }
      groupmember {
        nextToken
      }
      projectmember {
        nextToken
      }
      friends {
        nextToken
      }
      followers {
        nextToken
      }
      industries {
        nextToken
      }
      flames {
        nextToken
      }
      interests {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlame = /* GraphQL */ `
  query GetFlame($id: ID!) {
    getFlame(id: $id) {
      id
      profileID
      postID
      projectID
      groupID
      commentID
      targetProfileID
      createdAt
      updatedAt
    }
  }
`;
export const listFlames = /* GraphQL */ `
  query ListFlames(
    $filter: ModelFlameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        postID
        projectID
        groupID
        commentID
        targetProfileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      profileID
      toProfileID
      content
      hasAttachment
      type
      read
      status
      threadID
      postID
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        toProfileID
        content
        hasAttachment
        type
        read
        status
        threadID
        postID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageThread = /* GraphQL */ `
  query GetMessageThread($id: ID!) {
    getMessageThread(id: $id) {
      id
      profileID
      toProfileID
      content
      inbox
      type
      read
      status
      counterPartThreadID
      projectID
      groupID
      postID
      messages {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessageThreads = /* GraphQL */ `
  query ListMessageThreads(
    $filter: ModelMessageThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        toProfileID
        content
        inbox
        type
        read
        status
        counterPartThreadID
        projectID
        groupID
        postID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageAttachment = /* GraphQL */ `
  query GetMessageAttachment($id: ID!) {
    getMessageAttachment(id: $id) {
      id
      title
      type
      filePath
      messageID
      message {
        id
        profileID
        toProfileID
        content
        hasAttachment
        type
        read
        status
        threadID
        postID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessageAttachments = /* GraphQL */ `
  query ListMessageAttachments(
    $filter: ModelMessageAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        filePath
        messageID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPostAttachment = /* GraphQL */ `
  query GetPostAttachment($id: ID!) {
    getPostAttachment(id: $id) {
      id
      title
      type
      filePath
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPostAttachments = /* GraphQL */ `
  query ListPostAttachments(
    $filter: ModelPostAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        filePath
        postID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIndustry = /* GraphQL */ `
  query GetIndustry($id: ID!) {
    getIndustry(id: $id) {
      id
      title
      content
      profiles {
        nextToken
      }
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIndustrys = /* GraphQL */ `
  query ListIndustrys(
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIndustryPost = /* GraphQL */ `
  query GetIndustryPost($id: ID!) {
    getIndustryPost(id: $id) {
      id
      industryID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIndustryPosts = /* GraphQL */ `
  query ListIndustryPosts(
    $filter: ModelIndustryPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustryPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        industryID
        postID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIndustryProfile = /* GraphQL */ `
  query GetIndustryProfile($id: ID!) {
    getIndustryProfile(id: $id) {
      id
      industryID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIndustryProfiles = /* GraphQL */ `
  query ListIndustryProfiles(
    $filter: ModelIndustryProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustryProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        industryID
        profileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterest = /* GraphQL */ `
  query GetInterest($id: ID!) {
    getInterest(id: $id) {
      id
      title
      industries {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInterests = /* GraphQL */ `
  query ListInterests(
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterestProfile = /* GraphQL */ `
  query GetInterestProfile($id: ID!) {
    getInterestProfile(id: $id) {
      id
      interestID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInterestProfiles = /* GraphQL */ `
  query ListInterestProfiles(
    $filter: ModelInterestProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterestProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interestID
        profileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIndustryInterests = /* GraphQL */ `
  query GetIndustryInterests($id: ID!) {
    getIndustryInterests(id: $id) {
      id
      industryID
      interestID
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      interest {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIndustryInterestss = /* GraphQL */ `
  query ListIndustryInterestss(
    $filter: ModelIndustryInterestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustryInterestss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        industryID
        interestID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterestPost = /* GraphQL */ `
  query GetInterestPost($id: ID!) {
    getInterestPost(id: $id) {
      id
      interestID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInterestPosts = /* GraphQL */ `
  query ListInterestPosts(
    $filter: ModelInterestPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterestPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        interestID
        postID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriend = /* GraphQL */ `
  query GetFriend($id: ID!) {
    getFriend(id: $id) {
      id
      profileID
      FriendProfileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      friendProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFriends = /* GraphQL */ `
  query ListFriends(
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        FriendProfileID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShared = /* GraphQL */ `
  query GetShared($id: ID!) {
    getShared(id: $id) {
      id
      profileID
      sharedProfileID
      sharedPostID
      sharedGroupID
      sharedProjectID
      parentGroupID
      parentProject
      sharedProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      sharedPost {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      sharedGroup {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      sharedProject {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listShareds = /* GraphQL */ `
  query ListShareds(
    $filter: ModelSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        sharedProfileID
        sharedPostID
        sharedGroupID
        sharedProjectID
        parentGroupID
        parentProject
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      profileID
      itemID
      status
      type
      content
      linkType
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        itemID
        status
        type
        content
        linkType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProstsImportIdIndex = /* GraphQL */ `
  query ListProstsImportIdIndex(
    $importID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProstsImportIDIndex(
      importID: $importID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      nextToken
    }
  }
`;
export const listByGroupIdTypeIndex = /* GraphQL */ `
  query ListByGroupIdTypeIndex(
    $groupID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByGroupIDTypeIndex(
      groupID: $groupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPostByGroupIdCreatedAtIndex = /* GraphQL */ `
  query ListPostByGroupIdCreatedAtIndex(
    $groupID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostByGroupIDCreatedAtIndex(
      groupID: $groupID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      nextToken
    }
  }
`;
export const cognitoIdIndex = /* GraphQL */ `
  query CognitoIdIndex(
    $cognitoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cognitoIdIndex(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProfilesImportIdIndex = /* GraphQL */ `
  query ListProfilesImportIdIndex(
    $importID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesImportIDIndex(
      importID: $importID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMessagesByProfileIdCreatedAtIndex = /* GraphQL */ `
  query ListMessagesByProfileIdCreatedAtIndex(
    $profileID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesByProfileIDCreatedAtIndex(
      profileID: $profileID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        toProfileID
        content
        hasAttachment
        type
        read
        status
        threadID
        postID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchGroups = /* GraphQL */ `
  query SearchGroups(
    $filter: SearchableGroupFilterInput
    $sort: SearchableGroupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: SearchableProjectSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchProfiles = /* GraphQL */ `
  query SearchProfiles(
    $filter: SearchableProfileFilterInput
    $sort: SearchableProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        status
        searchString
        email
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

import { FC, useState } from 'react';
import FilterActivities from '../../filterActivies';
import Button from '../../button';
import { industryI } from '../../../types';

export const AskAdvisors: FC<any> = ({ isMobile }) => {
  const [filter, setFilter] = useState(null);
  const [filterOptions, setFilterOptions] = useState<industryI[]>([]);
  const onSubmitClick = () => {
    console.log('Submit btn clicked');
  };
  return (
    <>
      <textarea
        rows={10}
        cols={70}
        className={`section-1-wrapper__textarea`}
      ></textarea>
      <div className={`section-1-wrapper__buttons`}>
        <FilterActivities
          onFilter={setFilter}
          filterOptions={filterOptions}
          className={'white'}
        />
        <div className={'submit-btn-wrapper'}>
          <Button text={'Submit'} clickHandler={onSubmitClick} />
        </div>
        {!isMobile && (
          <div className={'info-msg'}>View Manaaki Terms and Conditions</div>
        )}
      </div>
      {isMobile && (
        <div className={'info-msg'}>View Manaaki Terms and Conditions</div>
      )}
    </>
  );
};

import { FC } from 'react';
import './style.scss';

const Input: FC<any> = ({
  placeholder,
  name,
  register,
  required,
  minLength,
  type,
  className,
  label,
}) => {
  return (
    <div className={`input-holder ${className}`}>
      {label && <label className="label">{label}</label>}
      <input
        name={name}
        placeholder={placeholder}
        type={type}
        ref={register({ required, minLength: minLength })}
      ></input>
    </div>
  );
};

export default Input;

import { createSlice } from "@reduxjs/toolkit";
import { messageI } from "./../../types";

interface messagesInitialStateI {
  items: messageI[];
  unseen: number;
  loading: boolean;
}

const initialState: messagesInitialStateI = {
  items: [],
  unseen: 4,
  loading: false,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
});

const { reducer } = messagesSlice;
export default reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { courseI, userI, articleI, postI } from './../../types';

interface activityFeedInitialStateI {
  activities: (postI | userI | courseI)[];
  postCardsList: postI[];
  loading: boolean;
}

const initialState: activityFeedInitialStateI = {
  activities: [
    // {
    //   type: "course",
    //   item: {
    //     _id: "a",
    //     tags: ["E-commerce", "Web Design"],
    //     backgroundImage: "https://picsum.photos/id/1/600",
    //     createdBy: {
    //       _id: "123abc",
    //       firstName: "Jackson",
    //       lastName: "Curtis",
    //       occupation: "Small Business Owner",
    //       image: "https://picsum.photos/id/1050/40",
    //       certified: true,
    //       role: "contractor",
    //     },
    //     createdAt: new Date(),
    //   },
    // },
    // {
    //   type: "article",
    //   item: {
    //     _id: "b",
    //     title: "How to establish your business as a digital power house.",
    //     tags: ["E-commerce", "Web Design"],
    //     backgroundImage: "https://picsum.photos/id/122/200",
    //     createdBy: {
    //       _id: "123abc",
    //       firstName: "John",
    //       lastName: "Smalls",
    //       occupation: "Advisor",
    //       image: "https://picsum.photos/id/1011/40",
    //       certified: true,
    //       role: "advisor",
    //     },
    //     createdAt: new Date(),
    //   },
    // },
    // },
  ],
  postCardsList: [],
  loading: false,
};

const activityFeedSlice = createSlice({
  name: 'activityFeed',
  initialState,
  reducers: {
    setActivityFeedData: (
      state,
      action: PayloadAction<(postI | userI | courseI)[]>
    ) => {
      state.activities = action.payload;
      state.loading = true;
    },
    setPostCardsList: (state, action: PayloadAction<postI[]>) => {
      state.postCardsList = action.payload;
    },
  },
});

export const {
  setActivityFeedData,
  setPostCardsList,
} = activityFeedSlice.actions;
const { reducer } = activityFeedSlice;
export default reducer;

import { FC } from 'react';
import './style.scss';

const InputWithDropDown: FC<any> = ({
  name,
  id,
  values,
  register,
  label,
  onChange,
}) => {
  return (
    <div className="input-with-dropdown">
      {label && <label className="label">{label}</label>}
      <select id={id} name={name} ref={register} onChange={onChange}>
        {values.map((item: any) => {
          return (
            <option
              value={item.value || item.title}
              key={item.id}
              hidden={item.hidden}
            >
              {item.value || item.title}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default InputWithDropDown;

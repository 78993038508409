import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import Title from '../../../../components/title';
import { AskAdvisors } from '../../content/AskAdvisors';
import { Questions } from '../../../../components/QuestionsList';
import { Advisors } from '../../content/Advisors';
import { RegistrationMsg } from '../../registration/RegistrationMsg';

const AdvisorsSection: FC<any> = () => {
  let { isMobile } = useSelector((state: RootState) => state.windowR);
  const { user } = useSelector((state: RootState) => state.auth);
  console.log('user', user);

  return (
    <>
      <div className={`right-section__top`}>
        <Title title="Ask your Advisors" />
      </div>
      <div className={`section-1-wrapper`}>
        {user.id ? <AskAdvisors isMobile={isMobile} /> : <RegistrationMsg />}

        <div className={'questions-section'}>
          <div className={`questions-section__top`}>
            <Title title="Your Questions" />
          </div>
          {user.id ? <Questions isMobile={isMobile} /> : <RegistrationMsg />}
        </div>
        <div className={'advisors'}>
          <div className={`advisors__top`}>
            <Title title="Your Advisors" />
          </div>
          {user.id ? <Advisors /> : <RegistrationMsg />}
        </div>
        <div className={'suggested-connections'}>
          <div className={`suggested-connections__top`}>
            <Title title="Suggested Connections" />
          </div>

          {user.id ? <Advisors /> : <RegistrationMsg />}
        </div>
      </div>
    </>
  );
};

export default AdvisorsSection;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userI } from './../../types';

interface authInitialStateI {
  user: any;
  loading: boolean;
}

const initialState: authInitialStateI = {
  // user: {
  //   id: '123abc',
  //   firstName: 'Jackson',
  //   lastName: 'Curtis',
  //   occupation: 'Small Business Owner',
  //   avatarImage: 'https://picsum.photos/id/1050/40/40',
  //   certified: true,
  //   type: 'contractor',
  // },
  user: {
    id: '',
    firstName: '',
    lastName: '',
  },
  loading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { userI } from './../../types';
import CheckCircle from '../../components/checkCircle';
import UserAvatar from './../../components/userAvatar';
import { toggleLeftSideNav } from '../../reducers/sideNav';
import { useDispatch } from 'react-redux';
import { dispatchType } from '../../reducers';
import logo from './../../assets/images/logo-small.png';
import './style.scss';

interface userInfoCardI {
  user: userI;
  showCross?: boolean;
  className?: string;
}

const UserInfoCard: FC<userInfoCardI> = ({
  user = {},
  showCross = false,
  className = '',
}) => {
  const { avatarImage, firstName, lastName, occupation, certified } = user;
  const dispatch: dispatchType = useDispatch();

  const toggleLeftSideMenu = () => {
    dispatch(toggleLeftSideNav(false));
  };
  return (
    <div className={`user-info-card ${className}`}>
      {user.id ? (
        <>
          <UserAvatar image={avatarImage ? avatarImage : logo} />
          <div className="text-wrapper">
            <div className="text-wrapper__top">
              <div className="text-wrapper__name">
                {firstName} {lastName}
              </div>
              {certified ? <CheckCircle /> : null}
            </div>
            <div className="text-wrapper__occupation">{occupation}</div>
          </div>
        </>
      ) : (
        <div className="not-logged-in">
          <FontAwesomeIcon icon={faUserCircle} className="no-user" />
          <span>Not Logged in</span>
        </div>
      )}
      {showCross && (
        <div className="icon-cross" onClick={toggleLeftSideMenu}>
          <FontAwesomeIcon icon={faWindowClose} className="cross__icon" />
        </div>
      )}
    </div>
  );
};

export default UserInfoCard;

import { FC, useState } from 'react';
import OtpInput from 'react-otp-input';
import './style.scss';
export const OtpWrapper: FC<any> = ({ onSubmit }) => {
  const [otpVal, setOtpVal] = useState('');
  const handleChange = (otp: any) => {
    setOtpVal(otp);
  };

  const submitOtp = () => {
    console.log(otpVal);
    onSubmit(otpVal);
  };
  return (
    <div className="otp-wrapper">
      <div>Enter Verification Code</div>
      <OtpInput
        value={otpVal}
        onChange={handleChange}
        numInputs={6}
        containerStyle={'otp-container'}
        inputStyle={'otp-input'}
        shouldAutoFocus={true}
      />
      <div className="submit-otp">
        <button onClick={submitOtp}>Submit</button>
      </div>
    </div>
  );
};

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import windowReducer from './window';
import authReducer from './auth';
import messagesReducer from './messages';
import notificationsReducer from './notifications';
import activityFeedReducer from './activityFeed';
import suggestedConnectionsReducer from './suggestedConnections';
import sideNavReducer from './sideNav';
import registrationReducer from './registration';

const store = configureStore({
  reducer: {
    windowR: windowReducer,
    auth: authReducer,
    notifications: notificationsReducer,
    messages: messagesReducer,
    activityFeed: activityFeedReducer,
    suggestedConnections: suggestedConnectionsReducer,
    sideNav: sideNavReducer,
    registration: registrationReducer,
  },
  middleware: [...getDefaultMiddleware(), logger],
  devTools: true,
});
export default store;
export type dispatchType = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const CheckCircle: FC = () => (
  <div className='check-circle'>
    <FontAwesomeIcon icon={faCheck} className='check-circle__icon' />
  </div>
);

export default CheckCircle;

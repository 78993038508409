import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface registrationI {
  showForm: boolean;
  showOtpPage: boolean;
}
const initialState: registrationI = {
  showForm: false,
  showOtpPage: false,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    toggleRegistrationForm: (state, action: PayloadAction<boolean>) => {
      state.showForm = action.payload;
    },
    toggleOtpPage: (state, action: PayloadAction<boolean>) => {
      state.showOtpPage = action.payload;
    },
  },
});
export const {
  toggleRegistrationForm,
  toggleOtpPage,
} = registrationSlice.actions;
const { reducer } = registrationSlice;
export default reducer;

import { FC, lazy, Suspense, useMemo, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import SideNav from '../containers/sideNav';
import TopNav from '../containers/topNav';
import Footer from '../containers/footer';
import BrowserTitle from './../components/browserTitle';
import RightSection from '../containers/rightSection';
import { resized } from '../reducers/window';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchType, RootState } from '../reducers';
import { setAuthListener } from '../api/login';
import { setUser } from '../reducers/auth';
const HomePage = lazy(() => import('./../pages/home'));

const Navigation: FC = () => {
  let loc = useLocation().pathname;

  const dispatch: dispatchType = useDispatch();
  let { isIpad, isMobile } = useSelector((state: RootState) => state.windowR);

  useEffect(() => {
    dispatch(
      resized({
        outerWidth: window.outerWidth,
        outerHeight: window.outerHeight,
      })
    );
    checkIfUserIsLoggedIn();
  }, []);

  const checkIfUserIsLoggedIn = async () => {
    const userData = await setAuthListener();
    dispatch(setUser(userData));
  };

  const browserTitle = useMemo(() => {
    if (loc === '/') return 'Home';
    return loc
      .replace('/', '')
      .replace(/\//g, ' / ')
      .split(/[_ ]/)
      .map((t) => t[0].toUpperCase() + t.substr(1))
      .join(' ');
  }, [loc]);

  return (
    <>
      <BrowserTitle title={browserTitle} />
      {!isIpad && !isMobile ? <SideNav /> : null}
      <div className="root-right">
        <TopNav />
        <div className="section-wrapper">
          <div className={'pages'}>
            <Suspense fallback={<div></div>}>
              <Switch>
                <Route path="/" component={HomePage} exact />
              </Switch>
            </Suspense>
          </div>
          {!isIpad && !isMobile && <RightSection />}
        </div>
        {isMobile && <Footer />}
      </div>
    </>
  );
};

export default Navigation;

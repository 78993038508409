import { FC } from 'react';
import Title from '../../../../components/title';
import ProjectCard from '../../../courseCard';
import UserInfoCard from '../../../../components/userInfoCard';
import './style.scss';

const OpenProjects: FC<any> = () => {
  const projectDetails = {
    name: 'The Car Company',
    profile: {
      id: '23',
      firstName: 'Hanu',
      lastName: 'De Jong',
      occupation: 'Small Business Owner',
      avatarImage:
        'https://manaaki-public143348-dev.s3-ap-southeast-2.amazonaws.com/public/da2a1257-3173-4541-813c-0bdc44175a4c.jpg',
    },
    mainImage: '',
    id: '1',
    createdAt: 'dsdsdsdsdsds',
  };

  const user = {
    id: '23',
    firstName: 'Hanu',
    lastName: 'De Jong',
    occupation: 'Small Business Owner',
    avatarImage:
      'https://manaaki-public143348-dev.s3-ap-southeast-2.amazonaws.com/public/da2a1257-3173-4541-813c-0bdc44175a4c.jpg',
  };
  return (
    <div className="open-project">
      <div className="right-section__top">
        <Title title="Open Project" />
        <div className="btn-wrapper">
          <button className="submit-quote">Submit a Quote</button>
        </div>
      </div>

      <ProjectCard course={projectDetails} />

      <div className="open-project__details">
        <div className="project-owner">
          <div className="title">Project Owner</div>
        </div>
        <div className="profile">
          <UserInfoCard user={user} className={'open-project'} />
        </div>
        <div className="member-details">
          <div className="item">
            <div className="item-1">
              <div className="label">Member Since</div>
              <div className="val">March 12, 2021</div>
            </div>
            <div className="item-2">
              <div className="label">Primary Industry</div>
              <div className="val">Marketing & Advertising </div>
            </div>
          </div>
          <div className="item-var">
            <div className="item-1">
              <div className="val">Location :</div>
            </div>
            <div className="item-2">
              <div className="label">Auckland, Wellington</div>
            </div>
          </div>
          <div className="item-var">
            <div className="item-1">
              <div className="val">Experience :</div>
            </div>
            <div className="item-2">
              <div className="label">Senior</div>
            </div>
          </div>
          <div className="item-var">
            <div className="item-1">
              <div className="val">Required Skills :</div>
            </div>
            <div className="item-2">
              <div className="label">Photoshop, UI, UE, XD</div>
            </div>
          </div>
          <div className="item-var">
            <div className="item-1">
              <div className="val">Rate :</div>
              <div className="label">$$</div>
            </div>
            <div className="item-2">
              <div className="label">$60 - $80 Per Hour</div>
            </div>
          </div>
        </div>

        <div className="project-brief">
          <div className="project-brief__title">Project Brief:</div>
          <div className="project-brief__content">
            The planning stage is arguably the most important, because what’s
            decided and mapped here sets the stage for the entire project. This
            is also the stage that requires client interaction and the
            accompanying attention to detail. Requirements analysis. This
            includes client goals, target audience, detailed feature requests
            and as much relevant information
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenProjects;

import { FC, useState } from 'react';
import Title from '../../../../components/title';
import Input from '../../../../components/form/input';
import InputWithTextArea from '../../../../components/form/inputWithTextArea';
import AdvisorsList from '../../../../components/AdvisorsList';
import { useForm } from 'react-hook-form';
import './style.scss';

const Groups: FC<any> = () => {
  const { register, handleSubmit } = useForm<any>();
  const [advisorsList, setAdvisorsList] = useState<any[]>([
    {
      id: 1,
      firstName: 'Cameron',
      lastName: 'Smitthers',
      rating: 5,
    },
    {
      id: 2,
      firstName: 'Anne',
      lastName: 'Williams',
      rating: 5,
    },
    {
      id: 3,
      firstName: 'Mandeep',
      lastName: 'Ahuja',
      rating: 5,
    },
  ]);

  const [tabsList, setTabsList] = useState<any[]>([
    {
      id: 1,
      title: 'Create Group',
      active: true,
    },
    {
      id: 2,
      title: 'Group Settings',
      active: false,
    },
  ]);

  const onSubmit = (groupObj: any, e: any) => {
    console.log(groupObj);
  };

  const onAdvisorSelect = (clickedAdvisor: any) => {
    const newAdvisorList = [...advisorsList];
    newAdvisorList.map((advisor: any) => {
      if (advisor.id === clickedAdvisor.id && advisor.selected) {
        advisor.selected = false;
      } else if (advisor.id === clickedAdvisor.id && !advisor.selected) {
        advisor.selected = true;
      }
      return advisor;
    });
    setAdvisorsList(newAdvisorList);
  };

  const onTabSelect = (id: Number) => {
    const newTabsList = tabsList.map((item) => {
      if (item.id === id) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });

    setTabsList(newTabsList);
  };
  return (
    <div className="groups">
      <div className="right-section__top">
        <Title
          title="Create Group"
          showTabs={true}
          tabsArr={tabsList}
          onTabChange={onTabSelect}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="groups__form">
        <Input
          id={0}
          placeholder={'Better Business NZ'}
          name={'groupName'}
          register={register}
          type={'text'}
          label={'Group Name:'}
          className={'create-group name'}
        />
        <Input
          id={1}
          placeholder={'NZ SME Champions'}
          name={'tagline'}
          register={register}
          type={'text'}
          label={'Tagline'}
          className={'create-group tagine'}
        />
        <InputWithTextArea
          id={2}
          name={'bio'}
          register={register}
          label={'Group Bio'}
          rows={10}
          className={'create-group bio'}
        />
        <Input
          id={3}
          placeholder={'Public'}
          name={'visiblity'}
          register={register}
          type={'text'}
          label={'Group Visiblity:'}
          className={'create-group visiblity'}
        />
        <div className="right-section__top">
          <Title title="Invite Members" />
        </div>
        <Input
          id={4}
          placeholder={''}
          name={'username'}
          register={register}
          type={'text'}
          label={'Enter Username'}
          className={'create-group username'}
        />
        <div className="advisors-list-wrapper">
          <AdvisorsList
            advisorsList={advisorsList}
            onAdvisorSelect={onAdvisorSelect}
          />
        </div>
        <div className="submit-btn-wrapper">
          <button type="submit">SEND INVITES & CREATE GROUP</button>
        </div>
      </form>
    </div>
  );
};

export default Groups;

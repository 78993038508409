import { FC } from 'react';
import SuggestedConnections from '../../suggestedConnections';

export const Advisors: FC<any> = () => {
  return (
    <div className={'users-list'}>
      <SuggestedConnections />
    </div>
  );
};

import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import UsersList from "./../../components/usersList";

const SuggestedConnections: FC = () => {
  useEffect(() => {
    // call action to get suggested connections
  }, []);

  const navigateToSeeMore = () => {
    // add link to see more
  };
  const { users } = useSelector(
    (state: RootState) => state.suggestedConnections
  );
  return <UsersList users={users} title="" />;
};

export default SuggestedConnections;

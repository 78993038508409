import { FC, useState, useRef } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState, dispatchType } from '../../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { searchMain } from '../../../api/search';
import { mergeActivityFeedData } from '../../../utils/helperFunctions';
import {
  setActivityFeedData,
  setPostCardsList,
} from '../../../reducers/activityFeed/index';
import { fetchPosts, fetchProfiles, fetchProjects } from '../../../api';
import './style.scss';

const SearchBar: FC = () => {
  const [search, setSearch] = useState<string>('');
  let { isMobile } = useSelector((state: RootState) => state.windowR);
  const dispatch: dispatchType = useDispatch();

  const debounceRef = useRef<any>(null);
  const searchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    console.log(e.target.value);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      fetchByKeywords(e.target.value);
    }, 300);
  };

  const fetchByKeywords = async (keywords: String) => {
    if (keywords) {
      const { projectsArr, profilesArr, postsArr } = await searchMain(keywords);
      const mergedActivityData = mergeActivityFeedData(
        projectsArr,
        profilesArr,
        postsArr
      );
      dispatch(setPostCardsList(postsArr));
      dispatch(setActivityFeedData(mergedActivityData));
    } else {
      fetchAllData();
    }
  };

  const fetchAllData = () => {
    Promise.all([fetchProjects(), fetchProfiles(), fetchPosts()]).then(
      (activityFeedData) => {
        if (isMobile) {
          dispatch(setPostCardsList(activityFeedData[2]));
        }
        const mergedActivityData = mergeActivityFeedData(
          activityFeedData[0],
          activityFeedData[1],
          activityFeedData[2]
        );
        dispatch(setActivityFeedData(mergedActivityData));
      }
    );
  };

  const submitSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(search);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const focus = () => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  };
  return (
    <form className="search-bar" onSubmit={submitSearch}>
      {!isMobile && (
        <div className="search-bar__prompt">
          <div className="search-bar__prompt__text">Search Knowledge Base</div>
        </div>
      )}
      <div className="search-bar__search" onClick={focus}>
        <FontAwesomeIcon icon={faSearch} className="search-bar__search__icon" />
        <input
          type="search"
          value={search}
          onChange={searchChanged}
          className="search-bar__search__input"
          ref={inputRef}
        />
      </div>
      <button type="submit" className="search-bar__button">
        GO
      </button>
    </form>
  );
};

export default SearchBar;

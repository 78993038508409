import { FC } from 'react';
import {
  faFire,
  faComment,
  faCodeBranch,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

import { courseI } from '../../types';
import UserInfoCard from './../../components/userInfoCard';
import CircleIconButton from './../../components/circleIconButton';
import TagButton from './tagButton';
import logo from './../../assets/images/logo-small.png';
import ManaakiLogo from './../../assets/images/Manaaki-U-Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';

interface courseCardI {
  course: any;
}

const ActivityCard: FC<courseCardI> = ({ course }) => {
  const {
    name,
    profile,
    tags = [],
    mainImage,
    content,
    primaryIndustry,
  } = course;
  return (
    <div className={'course-card'}>
      {/* <img
        className="course-card__background-image"
        src={mainImage || logo}
        alt={`${name}`}
      /> */}
      <div className="course-card__top">
        <div className="manaaki-logo">
          <img src={ManaakiLogo} alt="manaaki" />
        </div>
        <div className="name">{name}</div>
        {/* <div className="course-card__title">{name}</div>
        <div className="course-card__tags">
          {tags.map((tag) => (
            <TagButton key={tag} text={tag} />
          ))}
        </div> */}
      </div>
      <div className={'course-card__content'}>
        <img
          className="course-card__background-image"
          src={mainImage || logo}
          alt={`${name}`}
        />
        <div className="content-main">
          <div>{content}</div>
          <div className="tags">
            <div className={'tag'}>Hospitality</div>
            <div className={'tag'}>Retail</div>
          </div>
        </div>
      </div>
      <div className="course-card__bottom">
        <div className="left-icons">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faFire} className="icon-flame" />
            <span className={'count'}>1800</span>
          </div>
          <div className={'icon-wrapper'}>
            <FontAwesomeIcon icon={faComment} className="icon-comment" />
            <span className={'count'}>128</span>
          </div>
          <div className={'icon-wrapper'}>
            <FontAwesomeIcon icon={faCodeBranch} className="icon-share" />
            <span className={'count'}>24</span>
          </div>
        </div>
        <div className="right-icon">
          <CircleIconButton icon={faArrowRight} size="sm" theme="blue-white" />
        </div>
        {/* <UserInfoCard user={profile ? profile : ({} as any)} />
        <div className="course-card__right-buttons">
          <CircleIconButton icon={faFire} size="sm" theme="dark-grey-white" />
          <CircleIconButton
            icon={faComment}
            size="sm"
            theme="dark-grey-white"
          />
          <CircleIconButton
            icon={faCodeBranch}
            size="sm"
            theme="dark-grey-white"
          />
          <CircleIconButton icon={faPlay} size="sm" theme="dark-grey-red" />
        </div> */}
      </div>
    </div>
  );
};

export default ActivityCard;

import { FC } from "react";
import { userI } from "../../types";

import UserAvatar from "./../userAvatar";
import "./style.scss";

interface usersListI {
  title?: string;
  users: userI[];
  seeMore?: Function;
}

const UsersList: FC<usersListI> = ({ title, users, seeMore }) => {
  return (
    <div className={"users-list"}>
      {title && <div className="users-list__title">{title}</div>}
      <div className="users-list__users">
        {users.map((user) => (
          <UserAvatar key={user.id} image={user.avatarImage} />
        ))}
        {seeMore && (
          <button className="users-list__see-more" onClick={() => seeMore}>
            See More
          </button>
        )}
      </div>
    </div>
  );
};

export default UsersList;

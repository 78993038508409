import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Input from '../../components/form/input';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faBlog,
  faComment,
  faEnvelope,
  faPlus,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

import RoundedButton from '../../components/roundedButton';
import { RootState, dispatchType } from '../../reducers';
import CircleIconButton from './../../components/circleIconButton';
import logo from './../../assets/images/logo-small.png';
import SearchBar from './searchBar';
import { toggleRegistrationForm } from '../../reducers/registration';
import { setUser } from '../../reducers/auth';
import { toggleLeftSideNav, toggleRightSideNav } from '../../reducers/sideNav';
import { AuthSignIn } from '../../api/login';
import { Auth } from 'aws-amplify';
import './style.scss';

interface IFormValues {
  username: string;
  password: string;
}

const TopNav: FC = () => {
  const { register, handleSubmit } = useForm<IFormValues>();
  let { unseen: unseenMessages } = useSelector(
    (state: RootState) => state.messages
  );
  let { unseen: unseenNotifications } = useSelector(
    (state: RootState) => state.notifications
  );
  let { isMobile } = useSelector((state: RootState) => state.windowR);
  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch: dispatchType = useDispatch();
  let history = useHistory();

  const toggleLeftSideMenu = () => {
    dispatch(toggleLeftSideNav(true));
  };

  // const resendConfirmationCode = async () => {
  //   try {
  //     await Auth.resendSignUp('tanmay');
  //     console.log('code resent successfully');
  //   } catch (err) {
  //     console.log('error resending code: ', err);
  //   }
  // };

  const onSubmit = async (data: IFormValues, e: any) => {
    console.log('data', data);
    const userObj = await AuthSignIn(data.username, data.password);
    console.log('userObj', userObj);
    dispatch(setUser(userObj));
    dispatch(toggleRegistrationForm(false));
    history.push('/');
  };

  const showForm = () => {
    dispatch(toggleRegistrationForm(true));
  };

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      dispatch(setUser({}));
      history.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return isMobile ? (
    <div className="mobile-header">
      <div>
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
      </div>
      <div>
        <SearchBar />
      </div>
      <div>
        <CircleIconButton icon={faPlus} onClick={toggleLeftSideMenu} />
      </div>
    </div>
  ) : (
    <div className="top-nav">
      <div className="top-nav__left">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
        <SearchBar />
      </div>
      {user.id ? (
        <div className="top-nav__right">
          <div className="row-flex">
            <CircleIconButton icon={faEnvelope} badge={unseenMessages} />
            <CircleIconButton icon={faBell} badge={unseenNotifications} />
            <CircleIconButton icon={faPlus} onClick={toggleLeftSideMenu} />
          </div>
          <div className="row-flex">
            <RoundedButton>
              <div className="rounded-button__text">
                Connections <br />
                <b>1800</b>
              </div>
            </RoundedButton>
            <RoundedButton className="right-icon" theme="grey">
              <div className="rounded-button__text">message us</div>
              <div className="icon-wrapper">
                <FontAwesomeIcon
                  icon={faComment}
                  className="icon-wrapper__icon"
                />
              </div>
            </RoundedButton>
            <RoundedButton
              className="right-icon"
              theme="grey"
              onClick={signOut}
            >
              <div className="rounded-button__text">
                <b>LogOut</b>
              </div>
            </RoundedButton>
          </div>
        </div>
      ) : (
        <div className="top-nav-right-section">
          <div className={'login-main'}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                name="username"
                placeholder="Enter Username"
                type="text"
                register={register}
                required
              />
              <Input
                name="password"
                placeholder="Enter password"
                type="password"
                register={register}
                required
                minLength={'6'}
              />
              <div className="login-btn-wrapper">
                <button type="submit" className={'login-btn'}>
                  Login
                </button>
                <div className="forgot-password-text">
                  Forgot <span className="password-text">Password?</span>
                </div>
              </div>
            </form>
          </div>
          <div className="register-main">
            <button className="register-btn" onClick={showForm}>
              Register
            </button>
            <div className="register-text">Haven't got an account yet?</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopNav;

import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface linkItemI {
  link: string;
  text: string;
  icon?: any;
  className?: string;
  clickHandler?: () => void;
}

const LinkItem: FC<linkItemI> = ({
  link,
  text,
  icon,
  className,
  clickHandler,
}) => {
  let loc = useLocation().pathname;
  return (
    <div
      className={clsx(
        "nav-item",
        loc === link && "nav-item--active",
        className
      )}
      onClick={clickHandler}
    >
      <Link to={link} className="nav-link">
        {icon && <FontAwesomeIcon icon={icon} className="nav-link__icon" />}
        <div className="nav-link__text">{text}</div>
      </Link>
    </div>
  );
};

export default LinkItem;

import { articleI, courseI, userI, postI } from './../../types';
export const mergeActivityFeedData = (
  projectData: courseI[],
  userProfileData: userI[],
  postsData: postI[]
) => {
  const projects = projectData.length;
  const profiles = userProfileData.length;
  const posts = postsData.length;
  const mergedActivityData = [];
  let i = 0,
    j = 0,
    k = 0,
    m = 0,
    counter = 0;
  while (i < projects && j < profiles) {
    projectData[i].activityType = 'project';
    userProfileData[j].activityType = 'user';
    if (counter % 2 !== 0) {
      mergedActivityData[m++] = projectData[i++];
      mergedActivityData[m++] = userProfileData[j++];
    } else {
      mergedActivityData[m++] = userProfileData[j++];
      mergedActivityData[m++] = projectData[i++];
    }
    counter++;
  }
  counter = 0;
  while (i < projects && k < posts) {
    projectData[i].activityType = 'project';
    postsData[k].activityType = 'post';
    if (counter % 2 !== 0) {
      mergedActivityData[m++] = postsData[k++];
      mergedActivityData[m++] = projectData[i++];
    } else {
      mergedActivityData[m++] = projectData[i++];
      mergedActivityData[m++] = postsData[k++];
    }
    counter++;
  }
  while (i < projects) {
    projectData[i].activityType = 'project';
    mergedActivityData[m++] = projectData[i++];
  }
  while (j < profiles) {
    userProfileData[j].activityType = 'user';
    mergedActivityData[m++] = userProfileData[j++];
  }
  while (k < posts) {
    let newPostsData: any = {};
    newPostsData = { ...postsData[k] };
    newPostsData.activityType = 'post';
    mergedActivityData[m++] = newPostsData;
    k++;
    //mergedActivityData[m++] = postsData[k++];
  }
  return mergedActivityData;
};

import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import './style.scss';

interface collapsableItem {
  text: string;
  link: string;
  icon?: any;
  image?: any;
}

const CollapsableItem: FC<collapsableItem> = ({ children, text, link, icon, image }) => {
  let loc = useLocation().pathname;
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    setOpen((o) => !o);
  };
  return (
    <div className='nav-item-wrapper'>
      <div className={clsx('nav-item', loc === link && 'nav-item--active')}>
        <Link to={link} className='nav-link'>
          {icon && <FontAwesomeIcon icon={icon} className='nav-link__icon' />}
          {image}
          <div className='nav-link__text'>{text}</div>
        </Link>
        <button className='chevron-wrapper' onClick={toggleOpen}>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx('chevron-wrapper__chevron', open && 'chevron-wrapper__chevron--open')}
          />
        </button>
      </div>
      {open ? <div className='collapsable-children'>{children}</div> : null}
    </div>
  );
};

export default CollapsableItem;

import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBell, faPlus } from "@fortawesome/free-solid-svg-icons";
import pizza from "../../assets/images/pizza.png";
import "./style.scss";

const Footer: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    setOpen((o) => !o);
  };
  const onIcon1Click = () => {
    console.log("icon1 clicked");
  };
  const onIcon2Click = () => {
    console.log("icon2 clicked");
  };
  const onIcon3Click = () => {
    console.log("icon3 clicked");
  };
  const onIcon4Click = () => {
    console.log("icon4 clicked");
  };
  return (
    <>
      <div className={`mobile-footer`}>
        <div className={"footer-options"}>
          <div className="icon-home">
            <FontAwesomeIcon icon={faHome} className="home__icon" />
          </div>
          <div className="icon-add" onClick={toggleOpen}>
            <FontAwesomeIcon icon={faPlus} className="add__icon" />
            <span className="new-title">New</span>
          </div>
          <div className="icon-bell">
            <FontAwesomeIcon icon={faBell} className="bell__icon" />
          </div>
        </div>
      </div>
      {open && (
        <div className="additional-icons">
          <div className="icon-1" onClick={onIcon1Click}></div>
          <div className="icon-2" onClick={onIcon2Click}></div>
          <div className="icon-3" onClick={onIcon3Click}></div>
          <div className="icon-4" onClick={onIcon4Click}></div>
          <img src={pizza} alt="pizza" />
        </div>
      )}
    </>
  );
};

export default Footer;

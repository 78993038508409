import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

interface userAvatarI {
  image?: string;
  link?: string;
}

const UserAvatar: FC<userAvatarI> = ({ image, link }) => {
  const retImage = useMemo(() => {
    return <img className="user-avatar" src={image} alt="user" />;
  }, [image]);

  return link ? <Link to={link}>retImage</Link> : retImage;
};

export default UserAvatar;

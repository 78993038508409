import { FC, useState } from 'react';
import Title from '../../../../components/title';
import {
  faPencilAlt,
  faUsers,
  faThLarge,
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import InputWithDropDown from '../../../../components/form/inputwithdropdown';
import Input from '../../../../components/form/input';
import InputWithTextArea from '../../../../components/form/inputWithTextArea';
import FileUpload from '../../../../components/form/fileUpload';
import ReactSlider from 'react-slider';
import AdvisorsList from '../../../../components/AdvisorsList';
import './style.scss';

const ActiveProjects: FC<any> = () => {
  const { register, handleSubmit } = useForm<any>();
  const [value, setValue] = useState<any>([25, 50]);
  const [advisorsList, setAdvisorsList] = useState<any[]>([
    {
      id: 1,
      firstName: 'Cameron',
      lastName: 'Smitthers',
      rating: 5,
    },
    {
      id: 2,
      firstName: 'Anne',
      lastName: 'Williams',
      rating: 5,
    },
    {
      id: 3,
      firstName: 'Mandeep',
      lastName: 'Ahuja',
      rating: 5,
    },
  ]);
  const [optionsArr, setOptionsArr] = useState<any[]>([
    {
      id: 0,
      title: 'Project',
      icon: faThLarge,
      active: true,
    },
    {
      id: 1,
      title: 'Article',
      icon: faPencilAlt,
      active: false,
    },
    {
      id: 2,
      title: 'Group',
      icon: faUsers,
      active: false,
    },
  ]);

  const onAdvisorSelect = (clickedAdvisor: any) => {
    const newAdvisorList = [...advisorsList];
    newAdvisorList.map((advisor: any) => {
      if (advisor.id === clickedAdvisor.id && advisor.selected) {
        advisor.selected = false;
      } else if (advisor.id === clickedAdvisor.id && !advisor.selected) {
        advisor.selected = true;
      }
      return advisor;
    });
    setAdvisorsList(newAdvisorList);
  };

  const onOptionChange = (id: Number) => {
    const arr = optionsArr.map((item) => {
      if (item.id === id) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setOptionsArr(arr);
  };

  const onFileChange = (e: any) => {
    console.log(e);
  };

  const onSubmit = (projectObj: any) => {
    console.log(projectObj);
  };
  return (
    <div className="active-projects">
      <div className="right-section__top">
        <Title
          title="Create"
          showOptions={true}
          optionsArr={optionsArr}
          onOptionChange={onOptionChange}
        />
      </div>
      <div className="right-section__top project-info">
        <Title title="Project Information" />
      </div>
      <div className={'project-form'}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-main">
          <div className="leftside-wrapper">
            <Input
              placeholder={'UI design required for new food app'}
              name={'projectname'}
              register={register}
              type={'text'}
              label={'Project Name'}
              className={'projectname active-project'}
            />
            <InputWithDropDown
              name={'category'}
              id={1}
              values={[
                {
                  id: 0,
                  value: 'Select all that apply',
                  hidden: true,
                },
                {
                  id: 1,
                  value: 'Category 1',
                },
                {
                  id: 2,
                  value: 'Category 2',
                },
              ]}
              register={register}
              label={'Category'}
              key={1}
            />
            <InputWithTextArea
              id={2}
              name={'projectbrief'}
              register={register}
              label={'Project Brief'}
              rows={10}
              className={'active-project project-brief'}
            />
            <Input
              placeholder={'Any'}
              name={'location'}
              register={register}
              type={'text'}
              label={'Location'}
              className={'location active-project'}
            />
            <InputWithDropDown
              name={'experience'}
              id={3}
              values={[
                {
                  id: 0,
                  value: 'Senior - 10+ years',
                  hidden: true,
                },
                {
                  id: 1,
                  value: 'Fresher',
                },
                {
                  id: 2,
                  value: 'Junior 2-5 Years',
                },
              ]}
              register={register}
              label={'Experience'}
            />
            <div className="slider-wrapper">
              <div className="label">Rate Ph:</div>
              <ReactSlider
                value={value}
                onBeforeChange={(val) =>
                  console.log('onBeforeChange value:', val)
                }
                onChange={(val) => {
                  console.log('onChange value:', val);
                  setValue(val);
                }}
                onAfterChange={(val) =>
                  console.log('onAfterChange value:', val)
                }
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                renderThumb={(props, state) => (
                  <div {...props}>{state.valueNow}</div>
                )}
              />
            </div>
            <InputWithDropDown
              name={'skills'}
              id={4}
              values={[
                {
                  id: 0,
                  value: 'PhotoShop',
                  hidden: true,
                },
                {
                  id: 1,
                  value: 'Javascript',
                },
                {
                  id: 2,
                  value: 'Typescript',
                },
              ]}
              register={register}
              label={'Skills (Tick All that apply)'}
            />
            <Input
              placeholder={''}
              name={'hours'}
              register={register}
              type={'number'}
              label={'Hours required (approx)'}
              className={'hours active-project'}
            />
          </div>
          <div className="rightside-wrapper">
            <div className="form-right">
              <FileUpload
                name={'file-upload'}
                onChange={onFileChange}
                label={'Upload Files:'}
              />
              <div className="label-title">
                <div>Invite Contractors </div>
              </div>
              <Input
                placeholder={''}
                name={'username'}
                register={register}
                type={'text'}
                label={'Enter Contractor username'}
                className={'username active-project'}
              />
              <div className="info-text">
                <div>
                  Based on the information you have entered, we can recommend
                  the following contractors
                </div>
              </div>
              <AdvisorsList
                advisorsList={advisorsList}
                onAdvisorSelect={onAdvisorSelect}
              />
            </div>
            <div className="btn-wrapper">
              <button type="submit">SEND INVITES & CREATE PROJECT</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActiveProjects;

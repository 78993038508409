import { FC } from 'react';
import './style.scss';
import clsx from 'clsx';

interface roundedButtonI {
  className?: string;
  theme?: 'red' | 'grey';
  onClick?: Function;
}

const RoundedButton: FC<roundedButtonI> = ({ theme = 'red', className, onClick, children }) => {
  const buttonClicked = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) onClick(event);
  };

  return (
    <button
      type='button'
      onClick={buttonClicked}
      className={clsx('rounded-button', className, 'rounded-button--' + theme)}
    >
      {children}
    </button>
  );
};

export default RoundedButton;

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { RootState, dispatchType } from '../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { toggleRightSideNav } from '../../reducers/sideNav';
import { toggleOtpPage } from '../../reducers/registration';
import { RegistrationForm } from './registration/RegistrationForm';
import AdvisorsSection from './pages/AdvisorsSection';
import OpenProjects from './pages/OpenProjects';
import ActiveProjects from './pages/ActiveProjects';
import EditProfile from './pages/EditProfile';
import Discussions from './pages/Discussions';
import Groups from './pages/Groups';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from '../../components/routesWithSubRoutes';
import { OtpWrapper } from '../../components/OtpInput';
import { AuthConfirmSignUp } from '../../api/registration';
import './style.scss';

const RightSection: FC<any> = () => {
  let { isIpad, isMobile } = useSelector((state: RootState) => state.windowR);
  const { showForm, showOtpPage } = useSelector(
    (state: RootState) => state.registration
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const routes = [
    {
      path: '/projects/open_projects',
      component: OpenProjects,
    },
    {
      path: '/projects/active_projects',
      component: ActiveProjects,
    },
    {
      path: '/profile/edit_profile',
      component: EditProfile,
    },
    {
      path: '/knowledge_base/discussions',
      component: Discussions,
    },
    {
      path: '/groups',
      component: Groups,
    },
    {
      path: '/',
      component: AdvisorsSection,
    },
  ];

  const dispatch: dispatchType = useDispatch();

  const toggleRightSideMenu = () => {
    dispatch(toggleRightSideNav(false));
  };

  const onOtpSubmit = async (otp: any) => {
    const response = await AuthConfirmSignUp(user.username, otp);
    console.log(response);
    if (response == 'SUCCESS') {
      dispatch(toggleOtpPage(false));
    }
  };

  return (
    <div className={`right-section ${isIpad ? 'full-width' : ''}`}>
      {showForm ? (
        <RegistrationForm />
      ) : (
        <>
          {(isIpad || isMobile) && (
            <div className="icon-cross" onClick={toggleRightSideMenu}>
              <FontAwesomeIcon icon={faWindowClose} className="cross__icon" />
            </div>
          )}
          {showOtpPage ? (
            <div className="otp-wrapper-registration">
              <OtpWrapper onSubmit={onOtpSubmit} />
            </div>
          ) : (
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          )}
        </>
      )}
    </div>
  );
};

export default RightSection;

import { createSlice } from '@reduxjs/toolkit';
import { notificationI } from './../../types';

interface notificationsInitialStateI {
  items: notificationI[];
  unseen: number;
  loading: boolean;
}

const initialState: notificationsInitialStateI = {
  items: [],
  unseen: 16,
  loading: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
});

const { reducer } = notificationsSlice;
export default reducer;

import { FC, ReactElement, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface starRatingI {
  rating?: number;
}
const StarRating: FC<starRatingI> = ({ rating }) => {
  let stars = useMemo(() => {
    let arr: Array<ReactElement> = [];
    if (!rating) return arr;
    for (let i = 1; i <= rating; i++) {
      arr.push(<FontAwesomeIcon key={i} icon={faStar} className='star-rating__icon' />);
    }
    if (Math.round(rating % 1))
      arr.push(<FontAwesomeIcon icon={faStarHalf} key={'half'} className='star-rating__icon' />);
    return arr;
  }, [rating]);
  return <div className='star-rating'>{stars}</div>;
};

export default StarRating;

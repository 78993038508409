import { FC } from "react";
import SideBar from "../../../components/sidebar";
import SideNav from "../../sideNav";
import { RootState, dispatchType } from "../../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { toggleLeftSideNav } from "../../../reducers/sideNav";

const LeftSideBarWrapper: FC<any> = () => {
  let { showLeftSideNav } = useSelector((state: RootState) => state.sideNav);
  let { isIpad, isMobile } = useSelector((state: RootState) => state.windowR);
  const dispatch: dispatchType = useDispatch();
  const toggleLeftSideMenu = (open: boolean) => {
    dispatch(toggleLeftSideNav(open));
  };
  return (
    <>
      {(isIpad || isMobile) && showLeftSideNav ? (
        <SideBar
          showSideNav={showLeftSideNav}
          toggleLeftSideMenu={toggleLeftSideMenu}
        >
          <SideNav showSideNav={showLeftSideNav} showCross={true} />
        </SideBar>
      ) : null}
    </>
  );
};

export default LeftSideBarWrapper;

import { FC, useEffect, useState } from 'react';
import Title from './../../../components/title';
import SectionTitle from './../../../components/form/sectionTitle';
import { useForm } from 'react-hook-form';
import InputWithDropDown from '../../../components/form/inputwithdropdown';
import Input from '../../../components/form/input';
import {
  fetchIndustryList,
  fetchInterestList,
  fetchAdvisorsList,
} from '../../../api';
import { APISignUp } from '../../../api/registration';
import { industryI } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AdvisorsList from '../../../components/AdvisorsList';
import {
  toggleRegistrationForm,
  toggleOtpPage,
} from '../../../reducers/registration';
import { setUser } from '../../../reducers/auth';
import { useDispatch } from 'react-redux';
import { dispatchType } from '../../../reducers';
export const RegistrationForm: FC<any> = () => {
  const dispatch: dispatchType = useDispatch();
  const { register, handleSubmit } = useForm<any>();
  const [industryList, setIndustryList] = useState<industryI[]>([]);
  const [interestList, setInterestList] = useState<any[]>([]);
  const [advisorsList, setAdvisorsList] = useState<any[]>([]);

  const locations = [
    { id: 1, title: 'Northland' },
    { id: 2, title: 'Auckland' },
    { id: 3, title: 'Waikato' },
    { id: 4, title: 'Bay of Plenty' },
    { id: 5, title: 'Gisborne' },
    { id: 6, title: "Hawke's Bay" },
    { id: 7, title: 'Taranaki' },
    { id: 8, title: 'Whanganui' },
    { id: 9, title: 'Manawatu' },
    { id: 10, title: 'Wairarapa' },
    { id: 11, title: 'Wellington' },
    { id: 12, title: 'Nelson Bays' },
    { id: 13, title: 'Marlborough' },
    { id: 14, title: 'West Coast' },
    { id: 15, title: 'Canterbury' },
    { id: 16, title: 'Timaru - Oamaru' },
    { id: 17, title: 'Otago' },
    { id: 18, title: 'Southland' },
    { id: 19, title: 'Other' },
  ];

  const accountTypes = [
    { id: 1, title: 'SME', value: 'SME' },
    { id: 2, title: 'Contractor', value: 'Contractor' },
    { id: 3, title: 'Advisor', value: 'Advisor' },
  ];

  const personalInformationInputs = [
    {
      id: 0,
      placeholder: 'First Name',
      name: 'firstName',
      register,
      type: 'text',
      label: 'First Name',
      className: 'registration',
    },
    {
      id: 1,
      placeholder: 'Last Name',
      name: 'lastName',
      register,
      type: 'text',
      label: 'Last Name',
      className: 'registration',
    },
    {
      id: 2,
      placeholder: 'Username',
      name: 'username',
      register,
      type: 'text',
      label: 'Username',
      className: 'registration',
    },
    {
      id: 3,
      placeholder: 'Password',
      name: 'password',
      register,
      type: 'password',
      label: 'Password',
      className: 'registration',
    },
    {
      id: 4,
      placeholder: 'Email',
      name: 'email',
      register,
      type: 'email',
      label: 'Email',
      className: 'registration',
    },
    {
      id: 5,
      placeholder: 'Location',
      name: 'location',
      register,
      type: 'dropDown',
      label: 'Location',
      options: locations,
      className: 'registration',
    },
    {
      id: 6,
      placeholder: 'Phone',
      name: 'phone',
      register,
      type: 'number',
      label: 'Phone',
      className: 'registration',
    },
  ];

  const businessInformationInputs = [
    {
      id: 0,
      placeholder: 'Business Name',
      name: 'businessName',
      register,
      type: 'text',
      label: 'Your Business Name',
      className: 'registration',
    },
    {
      id: 1,
      placeholder: 'Job Title',
      name: 'jobTitle',
      register,
      type: 'text',
      label: 'Job Title',
      className: 'registration',
    },
  ];

  // const advisorsList = [
  //   {
  //     id: 1,
  //     firstName: 'Cameron',
  //     lastName: 'Smitthers',
  //     rating: 5,
  //   },
  //   {
  //     id: 2,
  //     firstName: 'Anne',
  //     lastName: 'Williams',
  //     rating: 5,
  //   },
  //   {
  //     id: 3,
  //     firstName: 'Mandeep',
  //     lastName: 'Ahuja',
  //     rating: 5,
  //   },
  // ];

  useEffect(() => {
    fetchIndustries();
    fetchInterests();
  }, []);

  const fetchIndustries = async () => {
    const industryList = await fetchIndustryList();
    industryList?.sort((a, b) => a.title.localeCompare(b.title));
    setIndustryList(industryList);
  };

  const fetchInterests = async () => {
    const interestList = await fetchInterestList();
    console.log(interestList);
    setInterestList(interestList);
  };
  const onSubmit = async (userObj: any, e: any) => {
    const selectedInterests = getSelectedFields(interestList);
    const selectedAdvisors = getSelectedFields(advisorsList);
    userObj.selectedInterests = selectedInterests;
    userObj.selectdeAdvisors = selectedAdvisors;

    const response = await APISignUp(userObj);
    console.log(userObj);
    e.target.reset();
    dispatch(setUser(response));
    dispatch(toggleRegistrationForm(false));
    dispatch(toggleOtpPage(true));
  };

  const getSelectedFields = (arrayList: any[]) => {
    const selectedFileds = arrayList.filter((item) => {
      return item.selected;
    });
    return selectedFileds;
  };

  const onIndustryChange = (event: any) => {
    const selectedIndustry = industryList[event.target.selectedIndex];
    console.log(selectedIndustry);
    fetchAdvisors(selectedIndustry.title);
  };

  const fetchAdvisors = async (businessIndustryTitle: any) => {
    const advisors = await fetchAdvisorsList(businessIndustryTitle, 4);
    console.log(advisors);
    setAdvisorsList(advisors);
  };

  const onInterestSelect = (clickedInterest: any) => {
    const newInterestList = [...interestList];
    newInterestList.map((interest: any) => {
      if (interest.id === clickedInterest.id && interest.selected) {
        interest.selected = false;
      } else if (interest.id === clickedInterest.id && !interest.selected) {
        interest.selected = true;
      }
      return interest;
    });
    setInterestList(newInterestList);
  };

  const onAdvisorSelect = (clickedAdvisor: any) => {
    const newAdvisorList = [...advisorsList];
    newAdvisorList.map((advisor: any) => {
      if (advisor.id === clickedAdvisor.id && advisor.selected) {
        advisor.selected = false;
      } else if (advisor.id === clickedAdvisor.id && !advisor.selected) {
        advisor.selected = true;
      }
      return advisor;
    });
    setAdvisorsList(newAdvisorList);
  };

  return (
    <div className="registration-form">
      <div className="registration-form__title">
        <Title title="Register" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form-main">
        <div className="form-main__left">
          <div className="section account-type">
            <SectionTitle title={'Select Account Type'} />
            <InputWithDropDown
              name="accountType"
              id="accountType"
              values={accountTypes}
              register={register}
            />
          </div>

          <div className="section personal-info">
            <SectionTitle title={'Personal Information'} />
            {personalInformationInputs.map((input) => {
              let InputToShow = null;
              if (input.type === 'dropDown') {
                InputToShow = (
                  <InputWithDropDown
                    name={input.name}
                    id={input.id}
                    values={input.options}
                    register={input.register}
                    label={input.label}
                    key={input.id}
                  />
                );
              } else {
                InputToShow = (
                  <Input
                    placeholder={input.placeholder}
                    name={input.name}
                    register={input.register}
                    type={input.type}
                    label={input.label}
                    className={input.className}
                    key={input.id}
                  />
                );
              }
              return InputToShow;
            })}
          </div>
          <div className="section business-info">
            <SectionTitle title={'Business Information'} />
            {businessInformationInputs.map((input) => {
              return (
                <Input
                  placeholder={input.placeholder}
                  name={input.name}
                  register={input.register}
                  type={input.type}
                  label={input.label}
                  className={input.className}
                  key={input.id}
                />
              );
            })}
            <InputWithDropDown
              name="businessIndustry"
              id="businessIndustry"
              values={industryList}
              register={register}
              label={'Your business Industry'}
              onChange={onIndustryChange}
            />
          </div>
        </div>

        <div className="form-main__right">
          <div className="section section-interest">
            <SectionTitle title={'Select Your Interests'} />
            <div className="interest-list">
              {interestList.map((interest: any) => {
                return (
                  <div
                    className={'interest-wrapper'}
                    key={interest.id}
                    onClick={() => onInterestSelect(interest)}
                  >
                    <div className="interest-title">{interest.title}</div>
                    {interest.selected && (
                      <div className="check-icon-wrapper">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="icon-check"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="section section-advisors">
            <SectionTitle title={'Recommended Advisors'} />
            <AdvisorsList
              advisorsList={advisorsList}
              onAdvisorSelect={onAdvisorSelect}
            />
          </div>
          <div className="submit-btn-wrapper">
            <button type="submit">Continue</button>
          </div>
        </div>
      </form>
    </div>
  );
};

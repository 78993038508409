export const searchPostsByKeyword = /* GraphQL */ `
  query SearchPosts($keywords: String = "") {
    listPosts(
      filter: {
        and: {
          type: { ne: "reply" }
          groupID: { eq: "1" }
          searchString: { contains: $keywords }
        }
      }
    ) {
      items {
        title
        content
        groupID
        type
      }
    }
  }
`;

export const searchProfilesByKeyword = /* GraphQL */ `
  query SearchProfiles($keywords: String = "") {
    listProfiles(
      filter: {
        and: { status: { ne: 0 }, searchString: { contains: $keywords } }
      }
    ) {
      items {
        firstName
        lastName
        introduction
        status
      }
    }
  }
`;

export const searchProjectsByKeyword = /* GraphQL */ `
  query SearchProjects($keywords: String = "") {
    listProjects(
      filter: {
        and: { private: { ne: 1 }, searchString: { contains: $keywords } }
      }
    ) {
      items {
        private
        introduction
        content
        name
      }
    }
  }
`;

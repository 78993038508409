import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  faBell,
  faCog,
  faEnvelope,
  faHome,
  faStar,
  faThLarge,
  faUser,
  faUserCircle,
  faUsers,
  faSchool,
} from '@fortawesome/free-solid-svg-icons';

import { RootState, dispatchType } from '../../reducers';
import UserInfoCard from './../../components/userInfoCard';
import LinkItem from './linkItem';
import CollapsableItem from './collapsableItem';
import SocialMediaLinks from './socialMediaLinks';
import mUImage from './../../assets/images/Manaaki-U-icon.png';
import { toggleLeftSideNav, toggleRightSideNav } from '../../reducers/sideNav';
import './style.scss';

const MUImage = (
  <img src={mUImage} className="nav-link__image" alt="Manaaki U" />
);

const SideNav: FC<any> = ({ showSideNav, showCross }) => {
  const { user } = useSelector((state: RootState) => state.auth);

  let { isMobile, isIpad } = useSelector((state: RootState) => state.windowR);
  const dispatch: dispatchType = useDispatch();

  const toggleRightSideMenu = () => {
    if (isMobile || isIpad) {
      dispatch(toggleRightSideNav(true));
    }
  };
  return (
    <div className={`side-nav ${showSideNav ? 'show-slider' : ''}`}>
      <UserInfoCard user={user} showCross={showCross} />
      <div className="side-nav__scrollable">
        <LinkItem link="/" text="Home Feed" icon={faHome} />
        <CollapsableItem link="/profile" text="Profile" icon={faUserCircle}>
          <LinkItem
            link="/profile/edit_profile"
            text="Edit Profile"
            className="nav-item--inner"
            clickHandler={toggleRightSideMenu}
          />
        </CollapsableItem>
        <CollapsableItem link="/mail_box" text="Mail Box" icon={faEnvelope}>
          <LinkItem
            link="/mail_box/general"
            text="General"
            className="nav-item--inner"
          />
          <LinkItem
            link="/mail_box/projects"
            text="Projects"
            className="nav-item--inner"
          />
          <LinkItem
            link="/mail_box/manaaki_u"
            text="Manaaki U"
            className="nav-item--inner"
          />
          <LinkItem
            link="/mail_box/groups"
            text="Groups"
            className="nav-item--inner"
          />
        </CollapsableItem>
        <CollapsableItem link="/projects" text="Projects" icon={faThLarge}>
          <LinkItem
            link="/projects/open_projects"
            text="Open Projects"
            className="nav-item--inner"
            clickHandler={toggleRightSideMenu}
          />
          <LinkItem
            link="/projects/active_projects"
            text="Active Projects"
            className="nav-item--inner"
            clickHandler={toggleRightSideMenu}
          />
          <LinkItem
            link="/projects/completed_projects"
            text="Completed Projects"
            className="inner-item"
          />
        </CollapsableItem>
        <CollapsableItem link="/manaaki_u" text="Manaaki U" image={MUImage}>
          <LinkItem
            link="/manaaki_u/current_course"
            text="Current Course"
            className="nav-item--inner"
          />
          <LinkItem
            link="/manaaki_u/completed_courses"
            text="Completed Courses"
            className="nav-item--inner"
          />
        </CollapsableItem>
        <CollapsableItem
          link="/knowledge_base"
          text="Knowledge Base"
          icon={faSchool}
        >
          <LinkItem
            link="/knowledge_base/discussions"
            text="My Discussions"
            className="nav-item--inner"
            clickHandler={toggleRightSideMenu}
          />
        </CollapsableItem>
        <LinkItem link="/groups" text="Groups" icon={faUsers} />
        <LinkItem link="/feedback" text="Feedback" icon={faStar} />
        <LinkItem link="/notifications" text="Notifications" icon={faBell} />
        <LinkItem link="/connections" text="Connections" icon={faUser} />
        <LinkItem link="/settings" text="Settings" icon={faCog} />
      </div>
      <SocialMediaLinks />
    </div>
  );
};

export default SideNav;

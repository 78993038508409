import { createSlice } from "@reduxjs/toolkit";

interface sideNavInitialStateI {
  showLeftSideNav: boolean;
  showRightSideNav: boolean;
}
const initialState: sideNavInitialStateI = {
  showLeftSideNav: false,
  showRightSideNav: false,
};

const sideNavSlice = createSlice({
  name: "sideNav",
  initialState,
  reducers: {
    toggleLeftSideNav: (state, { payload }) => {
      state.showLeftSideNav = payload;
    },
    toggleRightSideNav: (state, { payload }) => {
      state.showRightSideNav = payload;
    },
  },
});

export const { toggleLeftSideNav, toggleRightSideNav } = sideNavSlice.actions;
const { reducer } = sideNavSlice;
export default reducer;

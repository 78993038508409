import { API } from 'aws-amplify';
import {
  searchPostsByKeyword,
  searchProfilesByKeyword,
  searchProjectsByKeyword,
} from '../../graphql/customquery/search';
export const searchMain = async (keywords: String) => {
  try {
    keywords = keywords.toLowerCase();
    const {
      data: {
        listPosts: { items: postsArr },
      },
    } = await (API.graphql({
      query: searchPostsByKeyword,
      variables: {
        filter: {
          and: {
            type: { ne: 'reply' },
            groupID: { eq: '1' },
            searchString: { contains: keywords },
          },
        },
        keywords,
      },
    }) as Promise<any>);

    console.log('Posts', postsArr);

    const {
      data: {
        listProfiles: { items: profilesArr },
      },
    } = await (API.graphql({
      query: searchProfilesByKeyword,
      variables: {
        filter: {
          and: {
            status: { ne: 0 },
            searchString: { contains: keywords },
          },
        },
        keywords,
      },
    }) as Promise<any>);
    console.log('Profiles', profilesArr);

    const {
      data: {
        listProjects: { items: projectsArr },
      },
    } = await (API.graphql({
      query: searchProjectsByKeyword,
      variables: {
        filter: {
          and: { private: { ne: 1 }, searchString: { contains: keywords } },
        },
        keywords: keywords,
      },
    }) as Promise<any>);
    console.log('Projects', projectsArr);

    return {
      projectsArr,
      profilesArr,
      postsArr,
    } as any;
  } catch (err) {
    console.log(err);
    return [];
  }
};

import { FC } from 'react';
import StarRating from '../starRating';
import './style.scss';

const AdvisorsList: FC<any> = ({ advisorsList, onAdvisorSelect }) => {
  return (
    <div className="advisors-list">
      {advisorsList.map((advisor: any) => {
        return (
          <div
            key={advisor.id}
            className={`advisor-card ${advisor.selected ? 'selected' : ''}`}
            onClick={() => onAdvisorSelect(advisor)}
          >
            <img
              src={
                advisor.avatarImage ||
                'https://manaaki-public143348-dev.s3-ap-southeast-2.amazonaws.com/public/21f906cb-5051-4d4e-8a3f-05b88e159737.jpg'
              }
              alt="user"
            />
            <div className="name">{advisor.firstName}</div>
            <div className="name">{advisor.lastName}</div>
            <StarRating rating={advisor.rating} />
          </div>
        );
      })}
    </div>
  );
};

export default AdvisorsList;

import { FC } from 'react';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface titleI {
  title: string;
  showTabs?: boolean;
  showOptions?: boolean;
  optionsArr?: any[];
  tabsArr?: any[];
  onTabChange?: Function;
  onOptionChange?: Function;
}

const Title: FC<titleI> = ({
  title,
  showTabs = false,
  showOptions = false,
  tabsArr = [],
  optionsArr = [],
  onTabChange = () => {},
  onOptionChange = () => {},
}) => {
  return showTabs ? (
    <div className="tabs">
      {tabsArr.map((item) => {
        return (
          <div
            className={`main-title ${item.active ? 'active' : ''}`}
            onClick={() => onTabChange(item.id)}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  ) : (
    <div className="title-wrapper">
      <div className="main-title">{title}</div>
      {showOptions && (
        <div className="options">
          {optionsArr.map((item) => {
            return (
              <div
                className={'option'}
                key={item.id}
                onClick={() => onOptionChange(item.id)}
              >
                <div className={`icon-wrapper ${item.active ? 'active' : ''}`}>
                  <FontAwesomeIcon
                    icon={item.icon}
                    className={`icon-${item.title}`}
                  />
                </div>
                <div className={'title'}>{item.title}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Title;

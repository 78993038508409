import { FC } from "react";
import SideBar from "../../../components/sidebar";
import RightSection from "../../rightSection";
import { RootState, dispatchType } from "../../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { toggleRightSideNav } from "../../../reducers/sideNav";

const RightSideBarWrapper: FC<any> = () => {
  let { showRightSideNav } = useSelector((state: RootState) => state.sideNav);
  let { isIpad, isMobile } = useSelector((state: RootState) => state.windowR);
  const dispatch: dispatchType = useDispatch();
  const toggleRightSideMenu = (open: boolean) => {
    dispatch(toggleRightSideNav(open));
  };
  return (
    <>
      {(isIpad || isMobile) && showRightSideNav ? (
        <SideBar
          showSideNav={showRightSideNav}
          direction={"right"}
          className={"right-side-slider"}
          // toggleLeftSideMenu={toggleLeftSideMenu}
        >
          <RightSection />
        </SideBar>
      ) : null}
    </>
  );
};

export default RightSideBarWrapper;

import { Route } from 'react-router-dom';

export const RouteWithSubRoutes = (route: any) => {
  return (
    <Route
      path={route.path}
      exact
      render={(props) => {
        return <route.component {...props} routes={route.routes} />;
      }}
    />
  );
};

import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
export const APISignUp = async (userObj: any) => {
  const {
    firstName,
    lastName,
    jobTitle,
    businessName,
    businessIndustry,
    username,
    email,
    password,
    phone,
    location,
    selectedInterests = [],
    role = 4,
    onboarded = 1,
    accountType,
    selectedAdvisors = [],
  } = userObj;
  if (username && password && password !== '' && email) {
    try {
      var signup_response: any = await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          email: email,
          //   phone_number: phone,
          //'custom:location':location,
          //'custom:industry':industry,
        },
      });
    } catch (err) {
      console.log('err', err);
      // await dispatch({
      // type: ON_ERROR,
      // payload: err.message,
      // })
      return 0;
    }
    console.log(signup_response);

    try {
      var profile_result: any = await API.graphql({
        query: mutations.createProfile,
        variables: {
          input: {
            cognitoId: signup_response.user.userDataKey,
            firstName: firstName,
            lastName: lastName,
            role: role,
            location: location,
            onboarded: onboarded,
            occupation: jobTitle,
            businessName: businessName,
            businessIndustry: businessIndustry,
            type: accountType,
            status: 1,
          },
        },
      });
      console.log('profile_result', profile_result);
      //create industry entries for this profile
      for (let i = 0; i < selectedInterests.length; i++) {
        var industry_result = await API.graphql({
          query: mutations.createInterestProfile,
          variables: {
            input: {
              profileID: profile_result.data.createProfile.id,
              interestID: selectedInterests[i].id,
            },
          },
        });
      }

      //add these advisor as friends
      for (let i = 0; i < selectedAdvisors.length; i++) {
        var friends_result = await API.graphql({
          query: mutations.createFriend,
          variables: {
            input: {
              profileID: profile_result.data.createProfile.id,
              FriendProfileID: selectedAdvisors[i].id,
            },
          },
        });
      }

      var obj = profile_result.data.createProfile;
      obj.username = username;
      console.log(obj);
      return obj;
    } catch (err) {}
  }
};

export const AuthConfirmSignUp = async (username: any, authCode: any) => {
  console.log('action - AuthConfirmSignUp - ' + username);
  if (username != '' && authCode != '') {
    let confirm_response = await Auth.confirmSignUp(username, authCode);
    return confirm_response;
  }
};

import { createSlice } from "@reduxjs/toolkit";
import { windowI } from "./../../types";

const initialState: windowI = {
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: false,
  isIpad: false,
  scroll: 0,
};

const windowSlice = createSlice({
  name: "window",
  initialState,
  reducers: {
    resized: (state, { payload }) => {
      state.width = payload.outerWidth;
      state.height = payload.outerHeight;
      state.isIpad = payload.outerWidth <= 1024 && payload.outerWidth >= 600;
      state.isMobile = payload.outerWidth <= 600;
    },
    scrolled: (state, { payload }) => {
      state.scroll = payload;
    },
  },
});

const { actions, reducer } = windowSlice;
export const { resized } = actions;
export default reducer;

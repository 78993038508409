import { API, graphqlOperation } from 'aws-amplify';
import {
  listPosts,
  listProfiles,
  listProjects,
  listIndustrys,
  listInterests,
} from '../graphql/queries';
import { listAdvisors } from '../graphql/customquery/query';
import { postI, userI, courseI, industryI, interestI } from '../types';

interface postsDataI {
  data: {
    listPosts: {
      items: postI[];
    };
  };
}

interface profilesDataI {
  data: {
    listProfiles: {
      items: userI[];
    };
  };
}

interface projectsDataI {
  data: {
    listProjects: {
      items: courseI[];
    };
  };
}

interface industrysListI {
  data: {
    listIndustrys: {
      items: industryI[];
    };
  };
}

interface interestsListI {
  data: {
    listInterests: {
      items: interestI[];
    };
  };
}

export const fetchPosts = async () => {
  try {
    const {
      data: {
        listPosts: { items },
      },
    } = await (API.graphql(
      graphqlOperation(listPosts, {
        filter: { groupID: { eq: '1' }, type: { eq: 'question' } },
      })
    ) as Promise<postsDataI>);
    return items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchProfiles = async () => {
  try {
    const {
      data: {
        listProfiles: { items },
      },
    } = await (API.graphql(
      graphqlOperation(listProfiles)
    ) as Promise<profilesDataI>);
    return items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchProjects = async () => {
  try {
    const {
      data: {
        listProjects: { items },
      },
    } = await (API.graphql(
      graphqlOperation(listProjects)
    ) as Promise<projectsDataI>);
    return items;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchIndustryList = async () => {
  try {
    const {
      data: {
        listIndustrys: { items },
      },
    } = await (API.graphql(
      graphqlOperation(listIndustrys)
    ) as Promise<industrysListI>);
    return items as industryI[];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchInterestList = async () => {
  try {
    const {
      data: {
        listInterests: { items },
      },
    } = await (API.graphql(
      graphqlOperation(listInterests)
    ) as Promise<interestsListI>);
    return items as interestI[];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchAdvisorsList = async (
  businessIndustry: String,
  role: Number
) => {
  try {
    const {
      data: {
        listProfiles: { items },
      },
    } = await (API.graphql({
      query: listAdvisors,
      variables: {
        filter: {
          status: { ne: 0 },
          businessIndustry: { eq: businessIndustry },
          role: { eq: role },
        },
        businessIndustry,
        role,
      },
    }) as Promise<any>);
    return items as any[];
  } catch (err) {
    console.log(err);
    return [];
  }
};

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      profileID
      name
      introduction
      content
      private
      mainImage
      status
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      profileID
      name
      introduction
      content
      private
      mainImage
      status
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      profileID
      name
      introduction
      content
      private
      mainImage
      status
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      profileID
      name
      introduction
      private
      mainImage
      primaryIndustry
      category
      location
      experience
      skills
      rateFrom
      rateTo
      hoursNeeded
      content
      shareCount
      status
      featured
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      profileID
      name
      introduction
      private
      mainImage
      primaryIndustry
      category
      location
      experience
      skills
      rateFrom
      rateTo
      hoursNeeded
      content
      shareCount
      status
      featured
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      profileID
      name
      introduction
      private
      mainImage
      primaryIndustry
      category
      location
      experience
      skills
      rateFrom
      rateTo
      hoursNeeded
      content
      shareCount
      status
      featured
      searchString
      posts {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      members {
        nextToken
      }
      flames {
        nextToken
      }
      shared {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupMember = /* GraphQL */ `
  mutation CreateGroupMember(
    $input: CreateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    createGroupMember(input: $input, condition: $condition) {
      id
      groupID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      groupID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupMember = /* GraphQL */ `
  mutation DeleteGroupMember(
    $input: DeleteGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    deleteGroupMember(input: $input, condition: $condition) {
      id
      groupID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProjectMember = /* GraphQL */ `
  mutation CreateProjectMember(
    $input: CreateProjectMemberInput!
    $condition: ModelProjectMemberConditionInput
  ) {
    createProjectMember(input: $input, condition: $condition) {
      id
      projectID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectMember = /* GraphQL */ `
  mutation UpdateProjectMember(
    $input: UpdateProjectMemberInput!
    $condition: ModelProjectMemberConditionInput
  ) {
    updateProjectMember(input: $input, condition: $condition) {
      id
      projectID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectMember = /* GraphQL */ `
  mutation DeleteProjectMember(
    $input: DeleteProjectMemberInput!
    $condition: ModelProjectMemberConditionInput
  ) {
    deleteProjectMember(input: $input, condition: $condition) {
      id
      projectID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      id
      profileID
      projectID
      content
      timeFrame
      rateFrom
      rateTo
      links
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      id
      profileID
      projectID
      content
      timeFrame
      rateFrom
      rateTo
      links
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      id
      profileID
      projectID
      content
      timeFrame
      rateFrom
      rateTo
      links
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      project {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      profileID
      title
      groupID
      projectID
      content
      type
      mainImage
      status
      parentPostID
      shareCount
      private
      introduction
      tags
      links
      createdAt
      searchString
      importID
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      replies {
        nextToken
      }
      comments {
        nextToken
      }
      flames {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      industries {
        nextToken
      }
      interests {
        nextToken
      }
      ratings {
        nextToken
      }
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      profileID
      title
      groupID
      projectID
      content
      type
      mainImage
      status
      parentPostID
      shareCount
      private
      introduction
      tags
      links
      createdAt
      searchString
      importID
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      replies {
        nextToken
      }
      comments {
        nextToken
      }
      flames {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      industries {
        nextToken
      }
      interests {
        nextToken
      }
      ratings {
        nextToken
      }
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      profileID
      title
      groupID
      projectID
      content
      type
      mainImage
      status
      parentPostID
      shareCount
      private
      introduction
      tags
      links
      createdAt
      searchString
      importID
      group {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      replies {
        nextToken
      }
      comments {
        nextToken
      }
      flames {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      industries {
        nextToken
      }
      interests {
        nextToken
      }
      ratings {
        nextToken
      }
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      profileID
      postID
      sharedID
      content
      groupID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      flames {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      profileID
      postID
      sharedID
      content
      groupID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      flames {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      profileID
      postID
      sharedID
      content
      groupID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      flames {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      profileID
      postID
      recipientProfileID
      content
      stars
      createdAt
      updatedAt
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      profileID
      postID
      recipientProfileID
      content
      stars
      createdAt
      updatedAt
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      profileID
      postID
      recipientProfileID
      content
      stars
      createdAt
      updatedAt
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      cognitoId
      firstName
      lastName
      role
      onboarded
      location
      type
      rateFrom
      rateTo
      rateCurrency
      behanceLink
      instagramLink
      facebookLink
      linkedinLink
      twitterLink
      introduction
      experience
      mainImage
      avatarImage
      rating
      manaakiCertified
      occupation
      businessName
      businessIndustry
      skills
      featured
      status
      searchString
      importID
      importEmail
      email
      posts {
        nextToken
      }
      groups {
        nextToken
      }
      projects {
        nextToken
      }
      groupmember {
        nextToken
      }
      projectmember {
        nextToken
      }
      friends {
        nextToken
      }
      followers {
        nextToken
      }
      industries {
        nextToken
      }
      flames {
        nextToken
      }
      interests {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      cognitoId
      firstName
      lastName
      role
      onboarded
      location
      type
      rateFrom
      rateTo
      rateCurrency
      behanceLink
      instagramLink
      facebookLink
      linkedinLink
      twitterLink
      introduction
      experience
      mainImage
      avatarImage
      rating
      manaakiCertified
      occupation
      businessName
      businessIndustry
      skills
      featured
      status
      searchString
      importID
      importEmail
      email
      posts {
        nextToken
      }
      groups {
        nextToken
      }
      projects {
        nextToken
      }
      groupmember {
        nextToken
      }
      projectmember {
        nextToken
      }
      friends {
        nextToken
      }
      followers {
        nextToken
      }
      industries {
        nextToken
      }
      flames {
        nextToken
      }
      interests {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      cognitoId
      firstName
      lastName
      role
      onboarded
      location
      type
      rateFrom
      rateTo
      rateCurrency
      behanceLink
      instagramLink
      facebookLink
      linkedinLink
      twitterLink
      introduction
      experience
      mainImage
      avatarImage
      rating
      manaakiCertified
      occupation
      businessName
      businessIndustry
      skills
      featured
      status
      searchString
      importID
      importEmail
      email
      posts {
        nextToken
      }
      groups {
        nextToken
      }
      projects {
        nextToken
      }
      groupmember {
        nextToken
      }
      projectmember {
        nextToken
      }
      friends {
        nextToken
      }
      followers {
        nextToken
      }
      industries {
        nextToken
      }
      flames {
        nextToken
      }
      interests {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFlame = /* GraphQL */ `
  mutation CreateFlame(
    $input: CreateFlameInput!
    $condition: ModelFlameConditionInput
  ) {
    createFlame(input: $input, condition: $condition) {
      id
      profileID
      postID
      projectID
      groupID
      commentID
      targetProfileID
      createdAt
      updatedAt
    }
  }
`;
export const updateFlame = /* GraphQL */ `
  mutation UpdateFlame(
    $input: UpdateFlameInput!
    $condition: ModelFlameConditionInput
  ) {
    updateFlame(input: $input, condition: $condition) {
      id
      profileID
      postID
      projectID
      groupID
      commentID
      targetProfileID
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlame = /* GraphQL */ `
  mutation DeleteFlame(
    $input: DeleteFlameInput!
    $condition: ModelFlameConditionInput
  ) {
    deleteFlame(input: $input, condition: $condition) {
      id
      profileID
      postID
      projectID
      groupID
      commentID
      targetProfileID
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      profileID
      toProfileID
      content
      hasAttachment
      type
      read
      status
      threadID
      postID
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      profileID
      toProfileID
      content
      hasAttachment
      type
      read
      status
      threadID
      postID
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      profileID
      toProfileID
      content
      hasAttachment
      type
      read
      status
      threadID
      postID
      createdAt
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createMessageThread = /* GraphQL */ `
  mutation CreateMessageThread(
    $input: CreateMessageThreadInput!
    $condition: ModelMessageThreadConditionInput
  ) {
    createMessageThread(input: $input, condition: $condition) {
      id
      profileID
      toProfileID
      content
      inbox
      type
      read
      status
      counterPartThreadID
      projectID
      groupID
      postID
      messages {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageThread = /* GraphQL */ `
  mutation UpdateMessageThread(
    $input: UpdateMessageThreadInput!
    $condition: ModelMessageThreadConditionInput
  ) {
    updateMessageThread(input: $input, condition: $condition) {
      id
      profileID
      toProfileID
      content
      inbox
      type
      read
      status
      counterPartThreadID
      projectID
      groupID
      postID
      messages {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageThread = /* GraphQL */ `
  mutation DeleteMessageThread(
    $input: DeleteMessageThreadInput!
    $condition: ModelMessageThreadConditionInput
  ) {
    deleteMessageThread(input: $input, condition: $condition) {
      id
      profileID
      toProfileID
      content
      inbox
      type
      read
      status
      counterPartThreadID
      projectID
      groupID
      postID
      messages {
        nextToken
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      toProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessageAttachment = /* GraphQL */ `
  mutation CreateMessageAttachment(
    $input: CreateMessageAttachmentInput!
    $condition: ModelMessageAttachmentConditionInput
  ) {
    createMessageAttachment(input: $input, condition: $condition) {
      id
      title
      type
      filePath
      messageID
      message {
        id
        profileID
        toProfileID
        content
        hasAttachment
        type
        read
        status
        threadID
        postID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageAttachment = /* GraphQL */ `
  mutation UpdateMessageAttachment(
    $input: UpdateMessageAttachmentInput!
    $condition: ModelMessageAttachmentConditionInput
  ) {
    updateMessageAttachment(input: $input, condition: $condition) {
      id
      title
      type
      filePath
      messageID
      message {
        id
        profileID
        toProfileID
        content
        hasAttachment
        type
        read
        status
        threadID
        postID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageAttachment = /* GraphQL */ `
  mutation DeleteMessageAttachment(
    $input: DeleteMessageAttachmentInput!
    $condition: ModelMessageAttachmentConditionInput
  ) {
    deleteMessageAttachment(input: $input, condition: $condition) {
      id
      title
      type
      filePath
      messageID
      message {
        id
        profileID
        toProfileID
        content
        hasAttachment
        type
        read
        status
        threadID
        postID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPostAttachment = /* GraphQL */ `
  mutation CreatePostAttachment(
    $input: CreatePostAttachmentInput!
    $condition: ModelPostAttachmentConditionInput
  ) {
    createPostAttachment(input: $input, condition: $condition) {
      id
      title
      type
      filePath
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePostAttachment = /* GraphQL */ `
  mutation UpdatePostAttachment(
    $input: UpdatePostAttachmentInput!
    $condition: ModelPostAttachmentConditionInput
  ) {
    updatePostAttachment(input: $input, condition: $condition) {
      id
      title
      type
      filePath
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePostAttachment = /* GraphQL */ `
  mutation DeletePostAttachment(
    $input: DeletePostAttachmentInput!
    $condition: ModelPostAttachmentConditionInput
  ) {
    deletePostAttachment(input: $input, condition: $condition) {
      id
      title
      type
      filePath
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createIndustry = /* GraphQL */ `
  mutation CreateIndustry(
    $input: CreateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    createIndustry(input: $input, condition: $condition) {
      id
      title
      content
      profiles {
        nextToken
      }
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIndustry = /* GraphQL */ `
  mutation UpdateIndustry(
    $input: UpdateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    updateIndustry(input: $input, condition: $condition) {
      id
      title
      content
      profiles {
        nextToken
      }
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIndustry = /* GraphQL */ `
  mutation DeleteIndustry(
    $input: DeleteIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    deleteIndustry(input: $input, condition: $condition) {
      id
      title
      content
      profiles {
        nextToken
      }
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createIndustryPost = /* GraphQL */ `
  mutation CreateIndustryPost(
    $input: CreateIndustryPostInput!
    $condition: ModelIndustryPostConditionInput
  ) {
    createIndustryPost(input: $input, condition: $condition) {
      id
      industryID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIndustryPost = /* GraphQL */ `
  mutation UpdateIndustryPost(
    $input: UpdateIndustryPostInput!
    $condition: ModelIndustryPostConditionInput
  ) {
    updateIndustryPost(input: $input, condition: $condition) {
      id
      industryID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIndustryPost = /* GraphQL */ `
  mutation DeleteIndustryPost(
    $input: DeleteIndustryPostInput!
    $condition: ModelIndustryPostConditionInput
  ) {
    deleteIndustryPost(input: $input, condition: $condition) {
      id
      industryID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createIndustryProfile = /* GraphQL */ `
  mutation CreateIndustryProfile(
    $input: CreateIndustryProfileInput!
    $condition: ModelIndustryProfileConditionInput
  ) {
    createIndustryProfile(input: $input, condition: $condition) {
      id
      industryID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIndustryProfile = /* GraphQL */ `
  mutation UpdateIndustryProfile(
    $input: UpdateIndustryProfileInput!
    $condition: ModelIndustryProfileConditionInput
  ) {
    updateIndustryProfile(input: $input, condition: $condition) {
      id
      industryID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIndustryProfile = /* GraphQL */ `
  mutation DeleteIndustryProfile(
    $input: DeleteIndustryProfileInput!
    $condition: ModelIndustryProfileConditionInput
  ) {
    deleteIndustryProfile(input: $input, condition: $condition) {
      id
      industryID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInterest = /* GraphQL */ `
  mutation CreateInterest(
    $input: CreateInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    createInterest(input: $input, condition: $condition) {
      id
      title
      industries {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInterest = /* GraphQL */ `
  mutation UpdateInterest(
    $input: UpdateInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    updateInterest(input: $input, condition: $condition) {
      id
      title
      industries {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInterest = /* GraphQL */ `
  mutation DeleteInterest(
    $input: DeleteInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    deleteInterest(input: $input, condition: $condition) {
      id
      title
      industries {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInterestProfile = /* GraphQL */ `
  mutation CreateInterestProfile(
    $input: CreateInterestProfileInput!
    $condition: ModelInterestProfileConditionInput
  ) {
    createInterestProfile(input: $input, condition: $condition) {
      id
      interestID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInterestProfile = /* GraphQL */ `
  mutation UpdateInterestProfile(
    $input: UpdateInterestProfileInput!
    $condition: ModelInterestProfileConditionInput
  ) {
    updateInterestProfile(input: $input, condition: $condition) {
      id
      interestID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInterestProfile = /* GraphQL */ `
  mutation DeleteInterestProfile(
    $input: DeleteInterestProfileInput!
    $condition: ModelInterestProfileConditionInput
  ) {
    deleteInterestProfile(input: $input, condition: $condition) {
      id
      interestID
      profileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createIndustryInterests = /* GraphQL */ `
  mutation CreateIndustryInterests(
    $input: CreateIndustryInterestsInput!
    $condition: ModelIndustryInterestsConditionInput
  ) {
    createIndustryInterests(input: $input, condition: $condition) {
      id
      industryID
      interestID
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      interest {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIndustryInterests = /* GraphQL */ `
  mutation UpdateIndustryInterests(
    $input: UpdateIndustryInterestsInput!
    $condition: ModelIndustryInterestsConditionInput
  ) {
    updateIndustryInterests(input: $input, condition: $condition) {
      id
      industryID
      interestID
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      interest {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIndustryInterests = /* GraphQL */ `
  mutation DeleteIndustryInterests(
    $input: DeleteIndustryInterestsInput!
    $condition: ModelIndustryInterestsConditionInput
  ) {
    deleteIndustryInterests(input: $input, condition: $condition) {
      id
      industryID
      interestID
      industry {
        id
        title
        content
        createdAt
        updatedAt
      }
      interest {
        id
        title
        content
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInterestPost = /* GraphQL */ `
  mutation CreateInterestPost(
    $input: CreateInterestPostInput!
    $condition: ModelInterestPostConditionInput
  ) {
    createInterestPost(input: $input, condition: $condition) {
      id
      interestID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInterestPost = /* GraphQL */ `
  mutation UpdateInterestPost(
    $input: UpdateInterestPostInput!
    $condition: ModelInterestPostConditionInput
  ) {
    updateInterestPost(input: $input, condition: $condition) {
      id
      interestID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInterestPost = /* GraphQL */ `
  mutation DeleteInterestPost(
    $input: DeleteInterestPostInput!
    $condition: ModelInterestPostConditionInput
  ) {
    deleteInterestPost(input: $input, condition: $condition) {
      id
      interestID
      postID
      post {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      interest {
        id
        title
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFriend = /* GraphQL */ `
  mutation CreateFriend(
    $input: CreateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    createFriend(input: $input, condition: $condition) {
      id
      profileID
      FriendProfileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      friendProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFriend = /* GraphQL */ `
  mutation UpdateFriend(
    $input: UpdateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    updateFriend(input: $input, condition: $condition) {
      id
      profileID
      FriendProfileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      friendProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFriend = /* GraphQL */ `
  mutation DeleteFriend(
    $input: DeleteFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    deleteFriend(input: $input, condition: $condition) {
      id
      profileID
      FriendProfileID
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      friendProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createShared = /* GraphQL */ `
  mutation CreateShared(
    $input: CreateSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    createShared(input: $input, condition: $condition) {
      id
      profileID
      sharedProfileID
      sharedPostID
      sharedGroupID
      sharedProjectID
      parentGroupID
      parentProject
      sharedProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      sharedPost {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      sharedGroup {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      sharedProject {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateShared = /* GraphQL */ `
  mutation UpdateShared(
    $input: UpdateSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    updateShared(input: $input, condition: $condition) {
      id
      profileID
      sharedProfileID
      sharedPostID
      sharedGroupID
      sharedProjectID
      parentGroupID
      parentProject
      sharedProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      sharedPost {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      sharedGroup {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      sharedProject {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteShared = /* GraphQL */ `
  mutation DeleteShared(
    $input: DeleteSharedInput!
    $condition: ModelSharedConditionInput
  ) {
    deleteShared(input: $input, condition: $condition) {
      id
      profileID
      sharedProfileID
      sharedPostID
      sharedGroupID
      sharedProjectID
      parentGroupID
      parentProject
      sharedProfile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      sharedPost {
        id
        profileID
        title
        groupID
        projectID
        content
        type
        mainImage
        status
        parentPostID
        shareCount
        private
        introduction
        tags
        links
        createdAt
        searchString
        importID
        updatedAt
      }
      sharedGroup {
        id
        profileID
        name
        introduction
        content
        private
        mainImage
        status
        searchString
        createdAt
        updatedAt
      }
      sharedProject {
        id
        profileID
        name
        introduction
        private
        mainImage
        primaryIndustry
        category
        location
        experience
        skills
        rateFrom
        rateTo
        hoursNeeded
        content
        shareCount
        status
        featured
        searchString
        createdAt
        updatedAt
      }
      profile {
        id
        cognitoId
        firstName
        lastName
        role
        onboarded
        location
        type
        rateFrom
        rateTo
        rateCurrency
        behanceLink
        instagramLink
        facebookLink
        linkedinLink
        twitterLink
        introduction
        experience
        mainImage
        avatarImage
        rating
        manaakiCertified
        occupation
        businessName
        businessIndustry
        skills
        featured
        status
        searchString
        importID
        importEmail
        email
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      profileID
      itemID
      status
      type
      content
      linkType
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      profileID
      itemID
      status
      type
      content
      linkType
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      profileID
      itemID
      status
      type
      content
      linkType
      createdAt
      updatedAt
    }
  }
`;

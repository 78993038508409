import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const SocialMediaLinks: FC = () => {
  return (
    <div className='social-media-links'>
      <div className='icon-wrapper'>
        <FontAwesomeIcon icon={faFacebookF} className='icon-wrapper__icon' />
      </div>
      <div className='icon-wrapper'>
        <FontAwesomeIcon icon={faGooglePlusG} className='icon-wrapper__icon' />
      </div>
      <div className='icon-wrapper'>
        <FontAwesomeIcon icon={faEnvelope} className='icon-wrapper__icon' />
      </div>
    </div>
  );
};

export default SocialMediaLinks;

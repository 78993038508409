import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";
import reportWebVitals from "./utils/reportWebVitals";
import Navigation from "./navigation";
import LeftSideBarWrapper from "./containers/sideBarWrapper/leftSideBarWrapper";
import RightSideBarWrapper from "./containers/sideBarWrapper/rightSideBarWrapper";
import store from "./reducers";

import "./styles/index.scss";

const myAppConfig = {
  // ...
  aws_appsync_graphqlEndpoint:
    "https://zq6oryufj5hzvfrnux4njs23qu.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-7iudtlsr3fh4zpibtyk5kw32mq",
  // ...

  //...Auth Config
  Auth: {
    identityPoolId: "ap-southeast-2:1db2e7a9-513e-42c9-97ae-69bfd3fedd3e",
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_uPoleDblR",
    userPoolWebClientId: "52t4klun59172u89t958rb3bom",
    mandatorySignIn: false,
  },
  //...
};

Amplify.configure(myAppConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Navigation />
        <LeftSideBarWrapper />
        <RightSideBarWrapper />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
